import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import './shared/prototypes';
import { HttpClientModule } from '@angular/common/http';
// modals
import { AdminDialogComponent } from './components/admin-dialog/admin-dialog.component';
import { AirPriceDetailDialogComponent } from './components/air-price-detail-dialog/air-price-detail-dialog.component';
import { ForgotPasswordDialogComponent } from './components/forgot-password-dialog/forgot-password-dialog.component';
import { DocViewerDialogComponent } from './components/doc-viewer-dialog/doc-viewer-dialog.component';
import { SearchDetailDialog } from './components/search-detail-dialog/search-detail-dialog.component';
import { ActivationDialogComponent } from './components/activation-dialog/activation-dialog.component';
import { SurcharDetailDialog } from './components/surcharge-detail-dialog/surcharge-detail-dialog.component';

// shared components
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { EmailAutocompleteComponent } from './shared/email-autocomplete/email-autocomplete.component';
import { SelectBoxComponent } from './shared/select-box/select-box.component';
import { AutocompleteComponent } from './shared/autocomplete/autocomplete.component';
import { SelectBoxItemPipe } from './shared/select-box-item-pipe/select-box-item.pipe';
import { UseUtcDirective } from './shared/p-calender-utc';
import { LocaleDatePipe } from './shared/locale-date-pipe/locale-date.pipe';
import { SumPipe } from './shared/sum-pipe/sum.pipe';
import { MinusDaysPipe } from './shared/minus-days-pipe/minus-days.pipe';
import { DecimalPipe } from './shared/decimal.pipe';
import { DocViewerBtnComponent } from './components/doc-viewer-btn/doc-viewer-btn.component';
import { CountDownComponent } from './components/countdown/countdown.component';
import { ToastComponent } from './framework/toast/toast.component';
import { DueComponentComponent } from './components/due-component/due-component.component';

// services
import { AuthGuard } from './services/auth-guard';
import { AuthenticationService } from './services/user/authentication.service';
import { UserService } from './services/user/user.service';
import { TokenInterceptor } from './services/token-interceptor.service';

import { AirportService } from './services/air/airport.service';
import { SeaportService } from './services/sea/seaport.service';
import { CompanyService} from './services/company/company.service';
import { CountryService } from './services/country/country.service';
import { ZipService } from './services/zip/zip.service';
import { TaxOfficeService } from './services/taxOffice/taxOffice.service';
import { CityService } from './services/city/city.service';
import { SelectBoxItemService } from './services/select-box-item.service';
import { ContainerTypeService } from './services/container-type/container-type.service';
import { ListService } from './services/list/list.service';
import { NotificationsService } from './services/notifications/notifications.service';
import { SearchLogClickHistoryService } from './services/search-log-click-history.service';

import { AppLoginComponent } from './pages/app.login.component';
import { ChangePasswordComponent } from './pages/user/change-password/change-password.component';

import { PStepsComponent } from './framework/p-steps/p-steps.component';

import { AirComponent } from './pages/air/air.component';
import { AirPriceRequestComponent } from './pages/air/price-request/price-request.component';
import { AirReservationInformationComponent } from './pages/air/reservation-information/reservation-information.component';
import { AirConfirmationComponent } from './pages/air/confirmation/confirmation.component';
import { NotificationsComponent } from './pages/user/notifications/notifications.component';
import { AirSearchComponent } from './pages/air/air-search/air-search.component';
import { ProfileComponent } from './pages/user/profile/profile.component';
import { ForgotPasswordComponent } from './pages/user/forgot-password/forgot-password.component';
import { SeaComponent } from './pages/sea/sea.component';
import { SeaPriceRequestComponent } from './pages/sea/price-request/price-request.component';
import { SeaReservationInformationComponent } from './pages/sea/reservation-information/reservation-information.component';
import { SeaConfirmationComponent } from './pages/sea/confirmation/confirmation.component';
import { SeaSearchComponent } from './pages/sea/search/search.component';
import { TrackingeComponent } from './pages/tracking/tracking/tracking.component';

import { AppRegisterComponent } from './pages/app.register.component';
import { SelectListComponent } from './framework/select-list/select-list.component';
import { DatepickerComponent } from './framework/datepicker/datepicker.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ListComponent } from './pages/list/list.component';
import { VerifyComponent } from './pages/verify.component';
import { SeaPriceDetailDialogComponent } from './components/sea-price-detail-dialog/sea-price-detail-dialog.component';
import { TariffNotFoundDialogComponent } from './components/tariff-not-found-dialog/tariff-not-found-dialog.component';
import {
    TruckPriceDetailDialogComponent
} from './components/truck-price-detail-dialog/truck-price-detail-dialog.component';
import {TruckComponent} from './pages/truck/truck.component';
import {TruckSearchComponent} from './pages/truck/truck-search/truck-search.component';
import {
    TruckReservationInformationComponent
} from './pages/truck/reservation-information/reservation-information.component';
import {TruckPriceRequestComponent} from './pages/truck/price-request/price-request.component';
import {TruckConfirmationComponent} from './pages/truck/confirmation/confirmation.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { LandPagePricesCarrouselComponent } from './components/land-page-prices-carrousel/land-page-prices-carrousel.component';
import { CustomersReviewCarouselComponent } from './components/customers-review-carousel/customers-review-carousel.component';
import { WopStepperCarrouselComponent } from './components/wop-stepper-carrousel/wop-stepper-carrousel.component';
import {FixedPriceService} from "./services/fixedPrice/fixed-price.service";
import { LandpageLoginComponent } from './components/landpage-login/landpage-login.component';
import { LandpageAccordionFaqComponent } from './components/landpage-accordion-faq/landpage-accordion-faq.component';
import { LandpageCarouselVideoComponent } from './components/landpage-carousel-video/landpage-carousel-video.component';
import { LandpageSearchPriceComponent } from './components/landpage-search-price/landpage-search-price.component';
import { DashboardFixedPricesComponent } from './components/dashboard-fixed-prices/dashboard-fixed-prices.component';
import { SeaListComponent } from './components/price-views/sea/list/sea-list.component';
import { SeaCardComponent } from './components/price-views/sea/card/sea-card.component';
import { AirListComponent } from './components/price-views/air/list/air-list.component';
import { AirCardComponent } from './components/price-views/air/card/air-card.component';
import { SeaCalendarComponent } from './components/price-views/sea/calendar/sea-calendar.component';
import { SeaCardModalComponent } from './components/price-views/sea/card-modal/sea-card-modal.component';
import { TruckCardComponent } from './components/price-views/truck/card/truck-card.component';
import { TruckListComponent } from './components/price-views/truck/list/truck-list.component';
import { AlternativeSegmentComponent } from './components/price-views/alternative-segment/alternative-segment.component';


import { SharedModule } from './shared/shared.module';
import { AppCodeModule } from './app.code.component';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppConfigComponent } from './app.config.component';
import { AppRightpanelComponent } from './app.rightpanel.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';
import { AppTimelineDemoComponent } from './pages/app.timelinedemo.component';
import { DisplayComponent } from './utilities/display.component';
import { ElevationComponent } from './utilities/elevation.component';
import { GridComponent } from './utilities/grid.component';
import { SpacingComponent } from './utilities/spacing.component';
import { TypographyComponent } from './utilities/typography.component';
import { TextComponent } from './utilities/text.component';
import { AppInvoiceComponent } from './pages/app.invoice.component';
import { AppHelpComponent } from './pages/app.help.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { MessageService } from 'primeng/api';
import { ErrorHandlerService } from './services/error-handler.service';
import { TranslateModule, TranslateLoader, TranslateService, TranslateStore } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MenuService } from './app.menu.service';
import { DialogService } from 'primeng/dynamicdialog';
import { TrackingService } from './services/tracking/tracking.service';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { PopupComponent } from './pages/invoice/popup/popup.component';
import { MaskNamePipe } from './utilities/pipes/mask-name.pipe';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
  }



@NgModule({
    imports: [
        SharedModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
            }
          }),
    ],
    declarations: [
        AppComponent,
        DashboardComponent,
        AppMainComponent,
        LoadingSpinnerComponent,
        AppRightpanelComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppConfigComponent,
        AppTopBarComponent,
        AppFooterComponent,
        DisplayComponent,
        ElevationComponent,
        GridComponent,
        SpacingComponent,
        TypographyComponent,
        TextComponent,
        AppLoginComponent,
        AppInvoiceComponent,
        AppHelpComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppTimelineDemoComponent,
        AppAccessdeniedComponent,
        EmailAutocompleteComponent,
        ChangePasswordComponent,
        SelectBoxComponent,
        ForgotPasswordComponent,
        DocViewerDialogComponent,
        SearchDetailDialog,
        ActivationDialogComponent,
        SurcharDetailDialog,
        DocViewerBtnComponent,
        AutocompleteComponent,
        PStepsComponent,
        AppRegisterComponent,
        SelectListComponent,
        DatepickerComponent,
        AdminDialogComponent,
        ForgotPasswordDialogComponent,
        AirPriceDetailDialogComponent,
        SelectBoxItemPipe,
        LocaleDatePipe,
        SumPipe,
        MinusDaysPipe,
        DecimalPipe,
        UseUtcDirective,
        VerifyComponent,
        ListComponent,
        AirComponent,
        ProfileComponent,
        AirSearchComponent,
        AirReservationInformationComponent,
        AirPriceRequestComponent,
        AirConfirmationComponent,
        NotificationsComponent,
        SeaComponent,
        SeaSearchComponent,
        TrackingeComponent,
        SeaPriceRequestComponent,
        SeaReservationInformationComponent,
        SeaConfirmationComponent,
        SeaPriceDetailDialogComponent,
        CountDownComponent,
        TariffNotFoundDialogComponent,
        ToastComponent,
        TruckComponent,
        TruckSearchComponent,
        TruckPriceDetailDialogComponent,
        TruckReservationInformationComponent,
        TruckPriceRequestComponent,
        TruckConfirmationComponent,
        LandingPageComponent,
        LandPagePricesCarrouselComponent,
        CustomersReviewCarouselComponent,
        WopStepperCarrouselComponent,
        LandpageLoginComponent,
        LandpageLoginComponent,
        LandpageAccordionFaqComponent,
        LandpageCarouselVideoComponent,
        LandpageSearchPriceComponent,
        DashboardFixedPricesComponent,
        DueComponentComponent,
        SeaListComponent,
        SeaCardComponent,
        AirListComponent,
        AirCardComponent,
        SeaCalendarComponent,
        SeaCalendarComponent,
        SeaCardModalComponent,
        TruckCardComponent,
        TruckListComponent,
        AlternativeSegmentComponent,
        InvoiceComponent,
        PopupComponent,
        MaskNamePipe
    ],
    providers: [
        AppMainComponent,  
        AppComponent,
        AirComponent,
        AuthenticationService,
        UserService,
        AirportService,
        SeaportService,
        CountryService,
        ZipService,
        TaxOfficeService,
        CityService,
        TrackingService,
        CompanyService,
        ListService,
        NotificationsService,
        FixedPriceService,
        SearchLogClickHistoryService,

        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        MessageService,
        MenuService,
        DialogService,
        SelectBoxItemService,
        SelectBoxItemPipe,
        ContainerTypeService,
        ErrorHandlerService,
        TranslateService,
        TranslateStore,
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
