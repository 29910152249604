import { Component, ComponentFactoryResolver, Injector, Input, AfterViewInit, OnInit, ViewChild, ViewContainerRef, OnDestroy } from '@angular/core';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';
import { StepperService } from '../../stepper.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


import { TruckModel } from '../../models/truck/truck-model';
import { TruckPackModel } from '../../models/truck/truck-pack-model';
import { TruckSearchComponent } from './truck-search/truck-search.component';
import { TruckPriceRequestComponent } from './price-request/price-request.component';
import { TruckReservationInformationComponent } from './reservation-information/reservation-information.component';
import { TruckConfirmationComponent } from './confirmation/confirmation.component';
import {MinDTO} from "../../models/min-dto";
import {TruckSearchService} from "../../services/truck/truck-search.service";
import { PStepsComponent } from 'src/app/framework/p-steps/p-steps.component';




@Component({
  selector: 'app-truck',
  templateUrl: './truck.component.html',
  styleUrls: ['./truck.component.scss']
})

export class TruckComponent extends BaseFormComponentComponent implements OnInit, AfterViewInit, OnDestroy {


  private unsubscriber : Subject<void> = new Subject<void>();

  @Input("step") step: number = 1;
  @Input("showOnlyComponent") showOnlyComponent: boolean = false;
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  @ViewChild("stepper", { static: false }) stepper: PStepsComponent;

  model = new TruckModel();
  showRigthPanel: boolean = true;
  panelDisplayOption: any =  {
    showInfo: true,
    showLocation: true,
    showCargo: true,
  };

  truckBodyTypes: MinDTO[] = [];

  productLoading: boolean = false;
  searchInfoLoading: boolean = false;
  reservationInfoLoading: boolean = false;
  initialOffsetTop: number = 0;

  stepSubscription: Subscription;
  stepComponents = {
    1: TruckSearchComponent,
    2: TruckPriceRequestComponent,
    3: TruckReservationInformationComponent,
    4: TruckConfirmationComponent
  }

  constructor(protected injector: Injector, private router: Router, private componentFactoryResolver: ComponentFactoryResolver, private stepperService: StepperService, private truckSearchService: TruckSearchService) {
    super(injector);
    //this.isLoading = true;

    
  }

  async ngOnInit(){
    // Ui'de yeniden ekle butonu olmadığı için varsayılan boş bir packModel ekleniyor.
    if(!this.model.packs) this.model.packs = [];
    if(!this.model.packs || this.model?.packs?.length  == 0 )  this.model.packs.push(new TruckPackModel());
    this.stepperService.changeCurrentStep(this.step);

    history.pushState(null, '');



    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {

      if(this.stepperService.step > 1) {
        history.pushState(null, '');
        this.container.clear();
        this.stepperService.changeCurrentStep(this.stepperService.step -1);
      }

    });

    this.truckBodyTypes = await this.truckSearchService.truckBodyTypes().toPromise();
  }



  ngAfterViewInit(): void {

    setTimeout(() => this.initialOffsetTop = document.querySelector<HTMLElement>(".header-img")?.offsetHeight, 500)

      /* Stepper'dan step numarası değiştirildiğinde... ilgili adıma ait component renderlanıyor.  */
      this.stepSubscription = this.stepperService.currentStep.subscribe((step: number) => {
        if(!this.showOnlyComponent) this.renderStepComponent(this.stepComponents[step]);
      })
  }

  ngOnDestroy(): void {
    this.stepSubscription.unsubscribe();
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  renderStepComponent(comp: any, airModel: TruckModel = this.model, step = 1) {
    this.container.clear();
    
    if(step == 4 && this.showOnlyComponent) {
      this.panelDisplayOption.showInfo = false;
      this.panelDisplayOption.showLocation = false;
    }

    if( !this.showOnlyComponent && ((step == 3 && !this.model?.selectedProduct) || this.model.shipmentNo) && (step != 4) ) {
      this.showRigthPanel = false;
    }


    window.scroll({ top: 0,  left: 0, behavior: 'smooth' });

    switch ( step ) {
      case 1:
          this.searchInfoLoading = true;
          break;
      case 2:
          this.productLoading = true;
          break;
      case 3:
          this.reservationInfoLoading = true;
          break;
      case 4:
          this.reservationInfoLoading = true;
          break;
      default:
          this.isLoading = true;
          break;
   }


  // create the component factory
  const dynamicComponentFactory = this.componentFactoryResolver.resolveComponentFactory(comp);
  // add the component to the view
  const componentRef = this.container.createComponent(dynamicComponentFactory);
  componentRef.instance['model'] = this.model;

  window.scroll({ top: this.initialOffsetTop,  left: 0, behavior: 'smooth' });

  this.isLoading = false;
  this.productLoading = false;
  this.reservationInfoLoading  = false;
  this.searchInfoLoading = false;

}

}
