import { Component, Input, Output, ViewEncapsulation } from '@angular/core';
import { SeaModel } from 'src/app/models/sea/sea-model';
import { EventEmitter } from '@angular/core';
import { MarketSeaProductsModel } from 'src/app/models/sea/market-sea-products.model';
import { TranslateService } from '@ngx-translate/core';

export interface ReservationData {
  selectedProduct: MarketSeaProductsModel | null;
  marketProductsDefId: number | null;
  productId: number | null;
}


@Component({
  selector: 'app-sea-list',
  templateUrl: './sea-list.component.html',
  styleUrls: ['./sea-list.component.scss']
})


export class SeaListComponent {
  @Input() alternativeSegmentStartIndex: number = null;
  @Input() model: SeaModel = null;
  @Input() loading: boolean = false;
  @Output() makeReservation: EventEmitter<ReservationData> = new EventEmitter<ReservationData>();
  @Output() priceInfo: EventEmitter<MarketSeaProductsModel> = new EventEmitter<MarketSeaProductsModel>();

  constructor(public translation: TranslateService) {

  }


  _makeReservation(selectedProduct: MarketSeaProductsModel = null, marketProductsDefId = null, productId = null) {
    this.makeReservation.emit({selectedProduct,marketProductsDefId,productId})
  }
  _priceInfo(product: MarketSeaProductsModel) {
    this.priceInfo.emit(product)
  }
}
