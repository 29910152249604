import {GenericEntity} from "../models/generic-entity.model";
import { MinDTO } from "../models/min-dto";
import {SimpleEntity} from "../models/simple-entity.model";


export default class EntityUtil {

  public static toSimple<E extends GenericEntity>(entity: E): SimpleEntity {
    let simple = new SimpleEntity();
    simple.id = entity.id;
    simple.firm = entity.firm;
    simple.version = entity.version;
    simple.name = entity["name"];
    return simple;
  }

  public static toMinDTO<E extends GenericEntity>(entity: E): MinDTO {
    let simple = new MinDTO();
    simple.id = entity.id;
    simple.name = entity["name"];
    return simple;
  }


}
