import { Component, OnInit, Input, Inject, Injector, OnDestroy} from '@angular/core';
import { StepperService } from 'src/app/stepper.service';
import { AirModel } from 'src/app/models/air/air-model';
import { AirComponent } from '../air.component';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';
import { AirSearchService } from 'src/app/services/air/air-search.service';
import { MarketAirProductsModel } from 'src/app/models/air/market-air-products.model';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AirPriceDetailDialogComponent } from 'src/app/components/air-price-detail-dialog/air-price-detail-dialog.component';
import { TariffNotFoundDialogComponent } from 'src/app/components/tariff-not-found-dialog/tariff-not-found-dialog.component';

@Component({
  selector: 'app-price-request',
  templateUrl: './price-request.component.html',
  styleUrls: ['./price-request.component.scss']
})
export class AirPriceRequestComponent extends BaseFormComponentComponent implements OnInit, OnDestroy {

  @Input("model") model: AirModel;
  adminDialogRef: DynamicDialogRef;
  viewType:string = 'card';

  constructor(
    protected injector: Injector, @Inject(AirComponent) public parent: AirComponent, private stepperService: StepperService, private airSearchService: AirSearchService) {
    super(injector)
    this.parent.isLoading = true;

  }

  async ngOnInit() {
    if (this.model.selectedProduct && this.parent.showOnlyComponent) {
      this.model.selectedProduct.isSelectedFromList = true;
      if (this.model?.products){
          this.model.products = [this.model.selectedProduct].concat(this.model.products).filter(product => {
              return (!product?.isOverLimit && product.availableCapacity && !product?.freights?.errorCode) || product?.isSelectedFromList;
          });
      }else {
          this.model.products = [];
          this.model.products = [this.model.selectedProduct].concat(this.model.products).filter(product => {
              return (!product?.isOverLimit && product.availableCapacity && !product?.freights?.errorCode) || product?.isSelectedFromList;
          });
      }

    }

    this.parent.stepper.notShowReservation = this.onlyShowPriceRequests();

  }
  ngOnDestroy() {
    if (this.adminDialogRef) {
      this.adminDialogRef.close();
    }
  }

  async makeReservation(selectedProduct: MarketAirProductsModel = null, marketProductsDefId = null) {


    if(selectedProduct) this.model.marketProductsDefId = selectedProduct.marketProductsDefId;
    if(!selectedProduct) this.model.marketProductsDefId = marketProductsDefId;

    this.model.selectedProduct = selectedProduct;

    try {
      this.isLoading = true;
      if (!this.model.selectedProduct || !this.model.selectedProduct.isSelectedFromList) {
        this.model = await this.airSearchService.offer(this.model).toPromise();
      } else {
        this.model = await this.airSearchService.insurance(this.model).toPromise();
      }
      this.isLoading = false;
      this.parent.reservationInfoLoading = true;
      window.scroll({ top: this.parent.initialOffsetTop, left: 0, behavior: 'smooth' });
      this.parent.container.clear();
      this.parent.model = this.model;

      this.stepperService.changeCurrentStep(3);
      this.parent.renderStepComponent(this.parent.stepComponents[3], this.model, 3);

      if(!this.parent.showOnlyComponent) {
        this.addQueryParam("page", "REZ")
      }

    } catch (error) {
      this.model.selectedProduct = null;
      // zaman aşımına uğrayan bir istek gerçekleşti ise bir önceki adıma geri dönüyoruz.
      if (error == 'searchTimeOut') {
        this.parent.container.clear();
        this.stepperService.changeCurrentStep(1);
        window.scroll({ top: this.parent.initialOffsetTop, left: 0, behavior: 'smooth' });
      }

      this.isLoading = false;
      this.parent.reservationInfoLoading = false;
      this.parent.isLoading = false;
    }


  }

  async priceInfo(product: MarketAirProductsModel) {
    this.adminDialogRef = this.dialogService.open(AirPriceDetailDialogComponent, { header: null, showHeader: false, modal: true, width: '800px', height: 'auto', closable: false, data: { product: product, model: this.model } });
  }

  onTimeEndAllocatedProduct(product: MarketAirProductsModel) {
    this.model.selectedProduct = null;
    this.parent.container.clear();
    this.stepperService.changeCurrentStep(1);
    this.showMessageStickly('warn', 'searchTimeOut')
    window.scroll({ top: this.parent.initialOffsetTop, left: 0, behavior: 'smooth' });
  }

  frontTariffNotFound(){
    this.adminDialogRef = this.dialogService.open(TariffNotFoundDialogComponent, { header: null, showHeader: false, modal: true, width: '800px', height: 'auto', closable: false, data: {type: "front"}});
  }
  endTariffNotFound(){
    this.adminDialogRef = this.dialogService.open(TariffNotFoundDialogComponent, {  header: null,  showHeader: false, modal: true, width: '800px', height: 'auto', closable: false, data: {type: "end"}});
  }
  frontEndTariffNotFound(){
    this.adminDialogRef = this.dialogService.open(TariffNotFoundDialogComponent, {  header: null,  showHeader: false, modal: true, width: '800px', height: 'auto', closable: false, data: {type: "frontend"}});
  }

  onlyShowPriceRequests() {

    if(!this.model.products) return true;


    const noPriceProductsSize = this.model.products.filter(product =>   ((((product?.isOverLimit && !product?.isSelectedFromList) ||  !product?.availableCapacity || product?.freights?.errorCode)) ))

    if(noPriceProductsSize.length == this.model.products.length) return true;

    return false;

  }
  onSelectViewType(viewType:string) {
    this.viewType = viewType;
  }

}
