import { Component, ComponentFactoryResolver, Injector, Input, AfterViewInit, OnInit, ViewChild, ViewContainerRef, OnDestroy } from '@angular/core';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';
import { StepperService } from '../../stepper.service';
import { Subscription } from 'rxjs';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


import { AirModel } from '../../models/air/air-model';
import { AirPackModel } from '../../models/air/air-pack-model';
import { AirSearchComponent } from './air-search/air-search.component';
import { AirPriceRequestComponent } from './price-request/price-request.component';
import { AirReservationInformationComponent } from './reservation-information/reservation-information.component';
import { AirConfirmationComponent } from './confirmation/confirmation.component';
import { SearchDetailDialog } from "../../components/search-detail-dialog/search-detail-dialog.component";
import { ClickHistorySubject, SearchLogClickHistoryService } from 'src/app/services/search-log-click-history.service'; 
import { SearchLogClickHistoryModel } from 'src/app/models/search-log-click-history.model';
import { PStepsComponent } from 'src/app/framework/p-steps/p-steps.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-air',
  templateUrl: './air.component.html',
  styleUrls: ['./air.component.scss']
})

export class AirComponent extends BaseFormComponentComponent implements OnInit, AfterViewInit, OnDestroy {


  private unsubscriber : Subject<void> = new Subject<void>();

  @Input("step") step: number = 1
  @Input("showOnlyComponent") showOnlyComponent: boolean = false;
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  @Input("modelType") modelType: string = "AIR";
  @ViewChild("stepper", { static: false }) stepper: PStepsComponent;

  model = new AirModel();
  showRigthPanel: boolean = true;
  panelDisplayOption: any =  {
    showInfo: true,
    showLocation: true,
    showCargo: true,
  }
  parentComponent: SearchDetailDialog;


  productLoading: boolean = false;
  searchInfoLoading: boolean = false;
  reservationInfoLoading: boolean = false;
  initialOffsetTop: number = 0;

  stepSubscription: Subscription;
  stepComponents = {
    1: AirSearchComponent,
    2: AirPriceRequestComponent,
    3: AirReservationInformationComponent,
    4: AirConfirmationComponent
  }

  constructor(protected injector: Injector, private searchLogHistoryService: SearchLogClickHistoryService, private componentFactoryResolver: ComponentFactoryResolver, private router: Router, private stepperService: StepperService) {
    super(injector);
    //this.isLoading = true;
  }

  ngOnInit(): void {
    // Ui'de yeniden ekle butonu olmadığı için varsayılan boş bir packModel ekleniyor.
    if(!this.model.packs) this.model.packs = [];
    if(!this.model.packs || this.model?.packs?.length  == 0 )  this.model.packs.push(new AirPackModel());

    if(!this.showOnlyComponent) {
      this.stepperService.changeCurrentStep(this.step);
    }
    

    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {


      if(this.stepperService.step != 1) {
  
        if(this.stepperService.step == 3) {
          this.container.clear();
          this.stepperService.changeCurrentStep(1);
        } else {
          this.container.clear();
          this.stepperService.changeCurrentStep(this.stepperService.step -1);
        }

      } else {
        setTimeout(() => { this.router.navigate(['home'], { replaceUrl: true })}, 200);
      }
    

    });

    ClickHistorySubject.subscribe((e: SearchLogClickHistoryModel) => {
      this.searchLogHistoryService.create(e).toPromise();
    })


  }



  ngAfterViewInit(): void {

    setTimeout(() => this.initialOffsetTop = document.querySelector<HTMLElement>(".header-img")?.offsetHeight, 500)

      /* Stepper'dan step numarası değiştirildiğinde... ilgili adıma ait component renderlanıyor.  */
      this.stepSubscription = this.stepperService.currentStep.subscribe((step: number) => {
        if(!this.showOnlyComponent) this.renderStepComponent(this.stepComponents[step]);
      })
  }

  ngOnDestroy(): void {
    this.stepSubscription.unsubscribe();
    this.unsubscriber.next();
    this.unsubscriber.complete();
    ClickHistorySubject.unsubscribe();
  }

    closeDialog(){
        if (this.parentComponent) this.parentComponent.closeDialog();
    }



  renderStepComponent(comp: any, airModel: AirModel = this.model, step = 1) {
      this.container.clear();
      
      if(step == 4 && this.showOnlyComponent) {
        this.panelDisplayOption.showInfo = false;
        this.panelDisplayOption.showLocation = false;
      }

      if( !this.showOnlyComponent && ((step == 3 && !this.model?.selectedProduct) || this.model.shipmentNo) && (step != 4) ) {
        this.showRigthPanel = false;
      }


      window.scroll({ top: 0,  left: 0, behavior: 'smooth' });

      switch ( step ) {
        case 1:
            this.searchInfoLoading = true;
            break;
        case 2:
            this.productLoading = true;
            break;
        case 3:
            this.reservationInfoLoading = true;
            break;
        case 4:
            this.reservationInfoLoading = true;
            break;
        default:
            this.isLoading = true;
            break;
     }


    // create the component factory
    const dynamicComponentFactory = this.componentFactoryResolver.resolveComponentFactory(comp);
    // add the component to the view
    const componentRef = this.container.createComponent(dynamicComponentFactory);
    componentRef.instance['model'] = airModel;

    window.scroll({ top: this.initialOffsetTop,  left: 0, behavior: 'smooth' });

    this.isLoading = false;
    this.productLoading = false;
    this.reservationInfoLoading  = false;
    this.searchInfoLoading = false;

  }

}
