import { Component, ElementRef, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';;
import { TruckModel } from 'src/app/models/truck/truck-model';
import { MarketTruckProductsModel } from 'src/app/models/truck/market-truck-products.model';

export interface ReservationData {
  selectedProduct: MarketTruckProductsModel | null;
  marketProductsDefId: number | null;
  productId: number | null;
}


@Component({
  selector: 'app-truck-card',
  templateUrl: './truck-card.component.html',
  styleUrls: ['./truck-card.component.scss'],
})


export class TruckCardComponent {

  @ViewChildren('details') detailsElements: QueryList<ElementRef>;

  @Input() model: TruckModel = null;
  @Input() loading: boolean = false;
  @Output() makeReservation: EventEmitter<ReservationData> = new EventEmitter<ReservationData>();
  @Output() priceInfo: EventEmitter<MarketTruckProductsModel> = new EventEmitter<MarketTruckProductsModel>();

  constructor(public translation: TranslateService) {

  }

  _makeReservation(selectedProduct: MarketTruckProductsModel = null, marketProductsDefId = null, productId = null) {
    this.makeReservation.emit({selectedProduct,marketProductsDefId,productId})
  }
  _priceInfo(product: MarketTruckProductsModel) {
    this.priceInfo.emit(product)
  }
  toggleDetails(index: number): void {
    const detailsElement = this.detailsElements.toArray()[index].nativeElement;
    const isExpanded = detailsElement.classList.contains('expanded');

    if (isExpanded) {
      detailsElement.style.maxHeight = '0';
      detailsElement.classList.remove('expanded');
    } else {
      detailsElement.style.maxHeight = detailsElement.scrollHeight + 'px';
      detailsElement.classList.add('expanded');
    }
  }

}
