import { Component, Input, Output, ViewEncapsulation } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { MarketSeaProductsModel } from 'src/app/models/sea/market-sea-products.model';
import { MarketAirProductsModel } from 'src/app/models/air/market-air-products.model';
import { AirModel } from 'src/app/models/air/air-model';

export interface ReservationData {
  selectedProduct: MarketAirProductsModel | null;
  marketProductsDefId: number | null;
  productId: number | null;
}


@Component({
  selector: 'app-air-list',
  templateUrl: './air-list.component.html',
  styleUrls: ['./air-list.component.scss']
})


export class AirListComponent {

  @Input() model: AirModel = null;
  @Input() loading: boolean = false;
  @Output() makeReservation: EventEmitter<ReservationData> = new EventEmitter<ReservationData>();
  @Output() priceInfo: EventEmitter<MarketAirProductsModel> = new EventEmitter<MarketAirProductsModel>();



  _makeReservation(selectedProduct: MarketAirProductsModel = null, marketProductsDefId = null, productId = null) {
    this.makeReservation.emit({selectedProduct,marketProductsDefId,productId})
  }
  _priceInfo(product: MarketAirProductsModel) {
    this.priceInfo.emit(product)
  }
}
