import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

  public registerIcons(): void {
    this.iconRegistry.addSvgIcon(
      'tag',
      this.sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/tag.svg')
    );
    this.iconRegistry.addSvgIcon(
        'box',
        this.sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/box.svg')
    );
    this.iconRegistry.addSvgIcon(
        'arrow-right',
        this.sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/arrow-right.svg')
    );
    this.iconRegistry.addSvgIcon(
      'discount',
      this.sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/discount.svg')
  );
    
  }
}