import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'app-alternative-segment',
  templateUrl: './alternative-segment.component.html',
  styleUrls: ['./alternative-segment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlternativeSegmentComponent {


  @Input() display = true
  @Input() loading = true

  @Output() onShowAlternative: EventEmitter<any> = new EventEmitter<any>();

  searchAlternativeSegment() {
    this.onShowAlternative.emit();
  }
}
