import { Component, Input, Output, ViewEncapsulation } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { MarketSeaProductsModel } from 'src/app/models/sea/market-sea-products.model';
import { MarketAirProductsModel } from 'src/app/models/air/market-air-products.model';
import { AirModel } from 'src/app/models/air/air-model';

export interface ReservationData {
  selectedProduct: MarketAirProductsModel | null;
  marketProductsDefId: number | null;
  productId: number | null;
}

const weekDaysMap: { [key: number]: string } = {
  1: "Pts",
  2: "Sal",
  3: "Çar",
  4: "Per",
  5: "Cum",
  6: "Cmt",
  7: "Paz"
};

@Component({
  selector: 'app-air-list',
  templateUrl: './air-list.component.html',
  styleUrls: ['./air-list.component.scss']
})


export class AirListComponent {

  @Input() model: AirModel = null;
  @Input() loading: boolean = false;
  @Output() makeReservation: EventEmitter<ReservationData> = new EventEmitter<ReservationData>();
  @Output() priceInfo: EventEmitter<MarketAirProductsModel> = new EventEmitter<MarketAirProductsModel>();



  _makeReservation(selectedProduct: MarketAirProductsModel = null, marketProductsDefId = null, productId = null) {
    this.makeReservation.emit({selectedProduct,marketProductsDefId,productId})
  }
  _priceInfo(product: MarketAirProductsModel) {
    this.priceInfo.emit(product)
  }

  getShortWeekdays(days: string[]): string {
    if (!days || days.length === 0) return "";

    const allDays = ["1", "2", "3", "4", "5", "6", "7"];
    if (days.length === 7 && allDays.every(day => days.includes(day))) {
        return "Her Gün";
    }

    return days.map(day => weekDaysMap[Number(day)] || "").filter(Boolean).join(",");
  }
}
