import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AirModel } from 'src/app/models/air/air-model';
import { MarketAirProductsModel } from 'src/app/models/air/market-air-products.model';
import { AirComponent } from 'src/app/pages/air/air.component';
import { AirSearchService } from 'src/app/services/air/air-search.service';
import { AirPriceDetailDialogComponent } from '../air-price-detail-dialog/air-price-detail-dialog.component';
import {SeaModel} from "../../models/sea/sea-model";
import {SeaSearchService} from "../../services/sea/sea-search.service";
import {SeaComponent} from "../../pages/sea/sea.component";
import {SeaPriceDetailDialogComponent} from "../sea-price-detail-dialog/sea-price-detail-dialog.component";

@Component({
  selector: 'app-search-detail-dialog',
  templateUrl: './search-detail-dialog.component.html',
  styleUrls: ['./search-detail-dialog.component.scss']
})
export class SearchDetailDialog implements OnInit{

  product: MarketAirProductsModel = null;
  relatedId: number = null;
  operation: string = null;
  loading: boolean = true;
  name: string = null;

  // todo deniz için aynı şekilde deniz model yapılacak.
  airModel: AirModel = null;
  seaModel: SeaModel = null;


  @ViewChild("dialogContent", { read: ViewContainerRef }) dialogContent;
  priceInfoDialogRef: DynamicDialogRef;

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef, private dialogService: DialogService, private airService: AirSearchService, private seaService: SeaSearchService) {
    this.operation  = config.data.operation;
    this.relatedId = this.config.data.id;
  }

  async ngOnInit() {
    if(this.operation == 'AIR') {
      this.airModel = await this.airService.get(this.relatedId).toPromise();
      this.name = this.airModel.quoteNo;
      this.renderAirSearch();

    }else if (this.operation == 'SEA'){
        this.seaModel = await  this.seaService.get(this.relatedId).toPromise();
        this.name = this.seaModel.quoteNo;
        this.renderSeaSearch();
    }
  }

  async getSegment(airModel: AirModel) {
    if(airModel.arrivalPort && airModel.departurePort) {
      return await this.airService.segment(airModel.departurePort.id, airModel.arrivalPort.id).toPromise();
    }
    return null;
  }

    async getSegmentSea(seaModel: SeaModel) {
        if (seaModel.arrivalPort && seaModel.departurePort) {
            return await this.seaService.segment(seaModel.departurePort.id, seaModel.arrivalPort.id, seaModel.departureCountry.id, seaModel.arrivalCountry.id).toPromise();
        }
        return null;
    }


  async renderAirSearch() {
    try {

      let step = 1;
      if(this.airModel.offerSubStatus == 'CA'  ) step = 3;
      if(this.airModel.offerSubStatus == 'RQ' || this.airModel.offerSubStatus == 'PO') step = 3;
      if(['SC', 'OP', 'CL', 'AP'].includes(this.airModel.offerSubStatus)) step = 4;

      if(this.airModel.offerSubStatus == 'PR') {
        step = 2;
        this.airModel.segment = await this.getSegment(this.airModel);
      }
      if(this.airModel.offerSubStatus != 'PR') {
        this.airModel = await this.airService.search(this.airModel).toPromise();
      }
      

      this.dialogContent.clear();

      const componentRef =  this.dialogContent.createComponent(AirComponent, null, null);

      componentRef.instance.showRigthPanel = true;

      this.loading = true;
      componentRef.instance.model = this.airModel;
      componentRef.instance.modelType = this.operation;
      componentRef.instance.parentComponent = this;

      componentRef.instance.showOnlyComponent = true;
      componentRef.instance.step = step;
      setTimeout(() => {
        componentRef.instance.renderStepComponent(componentRef.instance.stepComponents[step]);
        this.loading = false;
      }, 1000 );


    } catch {
      this.loading = false;
    }

  }

    async renderSeaSearch() {
        try {

            let step = 1;
            if(this.seaModel.offerSubStatus == 'CA'  ) step = 3;
            if(this.seaModel.offerSubStatus == 'RQ' || this.seaModel.offerSubStatus == 'PO') step = 3;
            if(['SC', 'OP', 'CL', 'AP'].includes(this.seaModel.offerSubStatus)) step = 4;

            if(this.seaModel.offerSubStatus == 'PR') {
                step = 2;
                this.seaModel.segment =  await this.getSegmentSea(this.seaModel);
            }

            if(this.seaModel.offerSubStatus != 'PR') {
              this.seaModel = await this.seaService.search(this.seaModel).toPromise();
            }

            

            this.dialogContent.clear();

            const componentRef =  this.dialogContent.createComponent(SeaComponent, null, null);

            this.loading = true;
            componentRef.instance.showRigthPanel = true;
            componentRef.instance.model = this.seaModel;
            componentRef.instance.parentComponent = this;
            componentRef.instance.modelType = this.operation;

            componentRef.instance.showOnlyComponent = true;
            componentRef.instance.step = step;

            setTimeout(() => {
              componentRef.instance.renderStepComponent(componentRef.instance.stepComponents[step]);
              this.loading = false;
            }, 1000 );

        } catch {
             this.loading = false;
        }

    }

  closeDialog() {
    this.ref.close();
  }

  async showAirPriceInfo() {
      if(this.operation == 'AIR') {
          this.priceInfoDialogRef = this.dialogService.open(AirPriceDetailDialogComponent, { header: null, showHeader: false, modal:true,  width: '800px', height: 'auto', closable: false,  data: {product: this.airModel.selectedProduct, model: this.airModel}});
      }else if (this.operation == 'SEA'){
          this.priceInfoDialogRef = this.dialogService.open(SeaPriceDetailDialogComponent, { header: null, showHeader: false, modal:true,  width: '800px', height: 'auto', closable: false,  data: {product: this.seaModel.selectedProduct, model: this.seaModel}});

      }
  }

}
