import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppMainComponent } from 'src/app/app.main.component';
import { DocViewerDialogComponent } from 'src/app/components/doc-viewer-dialog/doc-viewer-dialog.component';
import { UserDocumentDTO } from 'src/app/models/auth/user-document.model';
import { UserModel } from 'src/app/models/auth/user.model';
import { ChangeLanguageService } from 'src/app/services/change-language.service';
import { UserService } from 'src/app/services/user/user.service';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';
import { countries } from 'src/assets/data/country';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends BaseFormComponentComponent implements OnInit {

  countries: any[] = [];
  user: UserModel = new UserModel();
  selectedDocumentType: string = null;
  dialogRef: DynamicDialogRef;

  userAvatarUrl: string = "/assets/layout/images/blank_profile.png";
  fileUrl: string;
  safeUrl: SafeUrl
  showDialog: boolean = false;
  showDocumentType: string = null;

  tradeRegistryGazetteToolTip: string = "documenViewOrUpload";
  signatureCircularsToolTip: string = "signatureCirculars";
  invoiceInfoToolTip: string = "invoiceInfo";
  companyRegisterationFormToolTip = "companyRegisterationForm";
  agentNetworkCertificateToolTip = "agentNetworkCertificate"
  selectedLanguage: string;
  languages: any[] = [{'name': 'Turkish', 'img': 'assets/icons/tr.png', 'code': 'tr-TR', 'lang': 'tr'}, {'name': 'English', 'img': 'assets/icons/en.png', 'code': 'en-US', 'lang': 'en'} ]

  @ViewChild("uploader") uploader: ElementRef;
  @ViewChild("uploaderAvatar") uploaderAvatar: ElementRef;

  constructor(protected injector: Injector, private sanitizer: DomSanitizer, private userService: UserService, private languageService: ChangeLanguageService,  public appMain: AppMainComponent) {
    super(injector)
    this.countries = countries;
  }


  async ngOnInit() {
    this.user = await this.userService.user().toPromise();
    this.selectedLanguage = this.languages.find(f => f.code == this.user.locale);

    const userAvatar = await this.userService.avatar().toPromise();
    if(userAvatar) this.userAvatarUrl = userAvatar.fileBase64Content;

    // uploader tooltip
    if(this.user.status != 'AP') {
      this.invoiceInfoToolTip = await this.translation.get('uploadOrViewDocument').toPromise();
      this.signatureCircularsToolTip = this.invoiceInfoToolTip;
      this.tradeRegistryGazetteToolTip = this.invoiceInfoToolTip;
      this.companyRegisterationFormToolTip = this.invoiceInfoToolTip;
      this.agentNetworkCertificateToolTip = this.invoiceInfoToolTip;
    } else {
      if(this.user.status == 'AP' && this.user.tradeRegistryGazette) {
        this.tradeRegistryGazetteToolTip = await this.translation.get('viewDocument').toPromise();
      }
      if(this.user.status == 'AP' && !this.user.tradeRegistryGazette) {
        this.tradeRegistryGazetteToolTip = await this.translation.get('notAllowUploadDocumentsMessage').toPromise();
      }

      if(this.user.status == 'AP' && this.user.signatureCirculars) {
        this.signatureCircularsToolTip = await this.translation.get('viewDocument').toPromise();
      }
      if(this.user.status == 'AP' && !this.user.signatureCirculars) {
        this.signatureCircularsToolTip = await this.translation.get('notAllowUploadDocumentsMessage').toPromise();
      }

      if(this.user.status == 'AP' && this.user.invoiceInfo) {
        this.invoiceInfoToolTip = await this.translation.get('viewDocument').toPromise();
      }
      if(this.user.status == 'AP' && !this.user.invoiceInfo) {
        this.invoiceInfoToolTip = await this.translation.get('notAllowUploadDocumentsMessage').toPromise();
      }

      if(this.user.status == 'AP' && this.user.companyRegisterationForm) {
        this.companyRegisterationFormToolTip = await this.translation.get('viewDocument').toPromise();
      }
      if(this.user.status == 'AP' && !this.user.companyRegisterationForm) {
        this.companyRegisterationFormToolTip = await this.translation.get('notAllowUploadDocumentsMessage').toPromise();
      }

      if(this.user.status == 'AP' && this.user.agentNetworkCertificate) {
        this.agentNetworkCertificateToolTip = await this.translation.get('viewDocument').toPromise();
      }
      if(this.user.status == 'AP' && !this.user.agentNetworkCertificate) {
        this.agentNetworkCertificateToolTip = await this.translation.get('notAllowUploadDocumentsMessage').toPromise();
      }

    }
    this.setCountryDialCoe();
  }



  async updateProfile(form, isFileUpload?) {
    if (!await this.isValid()) return;

    this.isLoading = true;
    try {

      const uploadPromises = [];

      let _signature_file: UserDocumentDTO = null;
      let _trade_file: UserDocumentDTO = null;
      let _invoice_file: UserDocumentDTO = null;


      if(this.user.invoiceInfo) {
        //uploadPromises.push(this.userService.upload(this.user.invoiceInfo, "INVOCE_INFO_DOC_TYPE").toPromise());
        //_invoice_file = this.user.invoiceInfo;
        //this.user.invoiceInfo = null;

      }

      if(this.user.signatureCirculars) {
        //uploadPromises.push(this.userService.upload(this.user.signatureCirculars, "SIGNATURE_CIRCULARS_DOC_TYPE").toPromise());
        //_signature_file = this.user.signatureCirculars;
       // this.user.signatureCirculars = null;
      }

      if(this.user.tradeRegistryGazette) {
        //uploadPromises.push(this.userService.upload(this.user.tradeRegistryGazette, "TRADE_REGISTRY_DOC_TYPE").toPromise());
        //_trade_file = this.user.tradeRegistryGazette;
       // this.user.tradeRegistryGazette = null;
      }

      this.user = await this.userService.updateUser(this.user).toPromise();

      this.setCountryDialCoe();
      // user dto'nun içerisinde güncelleniyor.
      //await Promise.all(uploadPromises)

      // this.user.signatureCirculars = _signature_file;
      // this.user.invoiceInfo = _invoice_file;
      // this.user.tradeRegistryGazette = _trade_file;

      if (!isFileUpload) this.showMessage('success', 'successUpdate');
      if (isFileUpload) this.showMessage('success', this.checkDocCount() === 3 ? 'fileUploadedAll' : 'fileUploaded');
      this.isLoading = false;

    } catch {
      this.isLoading = false;
    }


  }

  showUploader(documentType, force = false) {
    this.selectedDocumentType = documentType;

    if (force || !this.user[this.selectedDocumentType]) {
      this.uploader.nativeElement.click();
      return;
    }

    if (this.user[this.selectedDocumentType]) {
      this.onViewFile(this.user[this.selectedDocumentType]);
      return;
    }
  }

  showAvatarUpload() {
    this.uploaderAvatar.nativeElement.click();
  }

  async onViewFile(documentModel: UserDocumentDTO) {
    this.showDialog = true;
    if(['text/plain', 'application/pdf'].includes(documentModel.type)) {
        this.showDocumentType = 'pdf/txt'
    } else if (documentModel.type.includes('image')) {
        this.showDocumentType = 'image';
    } else if (documentModel.type.includes('officedocument') || documentModel.type.includes('application/msword') || documentModel.type.includes('application/octet-stream')) {
        this.showDocumentType = 'doc';
        /* eğer kayıtlı bir belge değil ise cache den okuyan url yi geri dönüyoruz. */
        if(!documentModel.uid) {
          this.fileUrl = this.userService.getDigitalUrlFromCache();
        } else {
          this.fileUrl = this.userService.geDigitalUrl(documentModel.uid, documentModel.type, documentModel.fileName);
        }
        return;
    }
    const byteCharacters = this._base64ToArrayBuffer(documentModel.fileBase64Content);
    var blob = new Blob([byteCharacters], { type: documentModel.type});

    this.fileUrl = URL.createObjectURL(blob);
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileUrl);

  }

  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

    checkDocCount() {
        let docCount = 0;

        if (this.user?.signatureCirculars) docCount++;
        if (this.user?.invoiceInfo) docCount++;
        if (this.user?.tradeRegistryGazette) docCount++;

        return docCount;

    }

  async onSelectFile(event: any) {
    let warnMessage = null;
    const extentions = ["gif","jpg", "tif", "jpeg", "png", "doc", "docx", "pdf"];


    if(this.showDialog) this.showDialog = false;
    let file = event.target.files[0] as File;


    const fileExtention = file.name.split(".").pop();

    if(!extentions.includes(fileExtention.toLocaleLowerCase())) {
      warnMessage = 'wrongMineType';
    }

    event.target.value = null;

    const availableMineType = ['application/msword', 'image/png', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'image/jpeg'].includes(file.type)

    if (!availableMineType) warnMessage = 'wrongMineType';
    if (file.size > 5242880 ) warnMessage = 'maxSize5MB';

    if (warnMessage) {
      this.user[this.selectedDocumentType] = null;
      this.showMessage('warn', warnMessage);
      return;
    }

    const docSize = this.checkDocCount();

    const doc = new UserDocumentDTO();
    doc.type = file.type
    doc.fileName = file.name;
    doc.fileBase64Content = this.clearbase64STR(await this.toBase64(file))
    this.user[this.selectedDocumentType] = doc;
    doc.file = file;

    /* doc veya docx uzantılı dosyaları görüntüleyebilmek için marketplace deki cache yazıyoruz 3. part eklenti google api sini kullanarak görüntülemeye olanak sağlıyor. bunun içinde bir link ile dosyaya erişmeye ihtiyaç duyuyor. cache deki dosyayı bir endpoint ile erişilebilir yapıyoruz. */
    try {
      if(['doc', 'docx'].includes(fileExtention)) {
        await this.userService.setCacheDocument(doc).toPromise();
      }
      await this.updateProfile(this.form, true);

    } catch (error) {
      this.showMessage('error', 'errorOnCacheFile');
    }


  }

  async changeAvatar(event: any) {


    let warnMessage = null;
    const extentions = ["jpg", "jpeg", "png"];

    let file = event.target.files[0] as File;

    const fileExtention = file.name.split(".").pop();

    if(!extentions.includes(fileExtention)) {
      warnMessage = 'wrongPhoto';
    }

    event.target.value = null;

    const availableMineType = ['application/msword', 'image/png', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'image/jpeg'].includes(file.type)

    if (!availableMineType) warnMessage = 'wrongPhoto';
    if (file.size > 5242880 ) warnMessage = 'maxSize1MB';

    if (warnMessage) {
      this.user[this.selectedDocumentType] = null;
      this.showMessage('warn', warnMessage);
      return;
    }

    const doc = new UserDocumentDTO();
    doc.type = file.type
    doc.fileName = file.name;
    doc.fileBase64Content = this.clearbase64STR(await this.toBase64(file))
    doc.file = file;

    try {
      this.isLoading = true;
      await this.userService.uploadAvatar(doc).toPromise();
      this.showMessage('success', 'avatarUploaded');
      this.userAvatarUrl = 'data:image/png;base64,' + doc.fileBase64Content;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }

  }

  clearbase64STR(dataURI) {
    const BASE64_MARKER = ';base64,';
    const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    return dataURI.substring(base64Index);
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  showKvkk(name) {
    this.dialogRef = this.dialogService.open(DocViewerDialogComponent, { header: "view", showHeader: false, modal:true, closable: true,  width: '50%', height: '100%',  data: {filePath: `/assets/data/documents/kvkk_${this.translation.currentLang}.html`, name: name, showButton: false}});
  }

  async onChangeLang(e: any) {

    if(!e || !e.value) return;

    try {
      await this.userService.setLocale(e.value.code).toPromise();

      this.translation.use(e.value.lang);
      localStorage.setItem('lang', e.value.lang);
      localStorage.setItem('locale', e.value.code);
      this.languageService.languageChanged.next(e.value.lang);

    } catch {

    }


  }

  setCountryDialCoe() {
    // kullanıcının gelen ülke kodu ile telefon kodu bulunuyor.
    const userCountryDial = this.countries.find(f => f.code == this.user.dialCountryCode && f.dial_code == this.user.dialPhoneCode);
    this.user.dialCode = userCountryDial || { "name": "Turkey", "dial_code": "+90", "emoji": "🇹🇷", "code": "TR" };
  }

}
