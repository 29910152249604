import {Component, Injector, Input, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {TruckModel} from 'src/app/models/truck/truck-model';
import {TruckSearchService} from 'src/app/services/truck/truck-search.service';
import {TruckPackModel} from 'src/app/models/truck/truck-pack-model';
import {BaseFormComponentComponent} from 'src/app/shared/base-form-component/base-form-component.component';
import {TruckComponent} from '../truck.component';
import {DatePipe} from '@angular/common';
import {StepperService} from 'src/app/stepper.service';
import {DecimalPipe} from 'src/app/shared/decimal.pipe';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DocViewerDialogComponent} from 'src/app/components/doc-viewer-dialog/doc-viewer-dialog.component';
import { MinDTO } from 'src/app/models/min-dto';

@Component({
    selector: 'app-truck-search',
    templateUrl: './truck-search.component.html',
    styleUrls: ['./truck-search.component.scss'],
    providers: [DecimalPipe],
    encapsulation: ViewEncapsulation.None
})
export class TruckSearchComponent extends BaseFormComponentComponent implements OnInit {

    @Input("model") model: TruckModel;
    nowDate = new Date();

    truckBodyTypes: MinDTO[] = [];
    termsDialog: DynamicDialogRef;
    isCheckboxSelected: boolean = false;

    constructor(protected injector: Injector, private decimalPipe: DecimalPipe,
                @Inject(TruckComponent) public parent: TruckComponent,
                private stepperService: StepperService, private datePipe: DatePipe,
                private router: Router, private truckSearchService: TruckSearchService) {
        super(injector)
    }

  async ngOnInit() {

        this.truckBodyTypes = await this.truckSearchService.truckBodyTypes().toPromise();

        this.model.products = null;
        this.model.selectedProduct = null;
        this.model.quoteNo = null;
        if(!this.model.packs || this.model?.packs?.length  == 0 ) this.addPack();
  
        this.calculateTotals();
  
        if (!this.model.kp) this.model.kp = 'F';
        this.onChangeKp(this.model.kp);
  
        /* p-calendar de dışında farklı bir component kullanılacak */
        if(this.model.readinessDate && !(this.model.readinessDate instanceof Date)) {
            this.model.readinessDate = new Date(`${String(this.model.readinessDate).substring(0,10).trim()}`);
        }
  
        this.parent.stepper.notShowReservation = false;


        if (!this.model.packs || this.model?.packs?.length == 0) {
            this.addPack();
        }

        console.log(this.parent.truckBodyTypes, "parentttt")
        this.truckBodyTypes = this.parent.truckBodyTypes;
    }

    onSelectZip(e: any, elementRef: any, zipField = 'departureZip') {
        // Eğer diğer seçildi ise postCode boş oluyor.
        if (e.isOptionOther) {
            elementRef.autoComplete.inputEL.nativeElement.value = e.name.trim().split(' ')[0]
            this.model[zipField].name = e.name.trim().split(' ')[0];
            return
        }
        ;

        elementRef.autoComplete.inputEL.nativeElement.value = e['postCode']
        this.model[zipField].name = e['postCode']
    }

    addPack() {
        if (!this.model.packs) this.model.packs = [];
        this.model.packs.push(new TruckPackModel());
    }

    removePack(index: number) {
        this.model.packs.splice(index, 1);
        this.calculateTotals();
    }

    offClick() {
        if (this.isCheckboxSelected == false) {
            this.showMessage('warn', 'wrongContractAgreement');
        }
    }

    async search(form) {


        let packHasValid = true;


        const valid = await this.isValid();

        if (!valid) return;


        if (this.model.service && this.model.service[0] == 'D' && (!this.model.departureZip && !this.model.departureZipTxt)) {
            this.showMessage('warn', 'departureZipRequired');
            return;
        }
        if (this.model.service && this.model.service[this.model.service.length - 1] == 'D' && (!this.model.arrivalZip && !this.model.arrivalZipTxt)) {
            this.showMessage('warn', 'arrivalZipRequired');
            return;
        }

        if (this.model.td == 'D') {
            if (this.model.packs.filter(f => !f.height || !f.lenght || !f.pieces || !f.perKg || !f.width).length > 0) {
                packHasValid = false;
            }
        }

        if (!this.model.totalVolume || !this.model.totalWeight || (!this.model.pieces && !this.model.calculatedPieces)) {
            packHasValid = false;
        }

        if (!packHasValid) {
            this.showMessage('warn', 'totalOrUnitRequired');
            return;
        }

        if (!this.model.commodity) {
            this.showMessage("warn", "commodityRequired");
            return;
        }


        try {
            this.parent.productLoading = true;
            window.scroll({top: this.parent.initialOffsetTop, left: 0, behavior: 'smooth'});


            /* Todo:
               tarih payload da 1 gün geri gidiyor nedeni anlaşılarak düzeltme uygulanacak.
             */
            // this.model.readinessDate.setDate(this.model.readinessDate.getDate() + 1);
            this.model.totalVolume = Number(String(this.model.totalVolume).replace(",", "."));

            // daha önce bulunan ürün varsa siliniyor.
            this.model.products = [];

            this.model = await this.truckSearchService.search(this.model).toPromise();
            this.parent.productLoading = false;
            window.scroll({top: this.parent.initialOffsetTop, left: 0, behavior: 'smooth'});
            this.parent.container.clear();

            this.parent.model = this.model;

            this.stepperService.changeCurrentStep(2);

        } catch {
            this.parent.productLoading = false;
        }

    }

    async getSegment() {
        if (this.model.arrivalPort && this.model.departurePort) {
            const segment = await this.truckSearchService.segment(this.model.departurePort.id, this.model.arrivalPort.id).toPromise();
            this.model.segment = segment;
        }
    }

    onChangeVehicleType() {


    }

    onChangeTD(td: string) {
        this.model.td = td;
        this.model.packs = [];
        this.model.packingType = null;
        this.model.calculatedPieces = 0;
        this.model.pieces = 0;

        this.model.totalVolume = null;
        this.model.cwg = null;
        this.model.totalWeight = null;

        if (td == 'D') this.addPack();
    }

    onChangeDepartureCountry() {
        this.model.departurePort = null;
        this.model.departureZip = null;
    }

    onChangeArrivalCountry() {
        this.model.arrivalPort = null;
        this.model.arrivalZip = null;
    }

    calculateTotals(event: any = null) {
        if (this.model.td == 'T') {
            const calculatedCwg = (Number(String(event?.value || this.model.totalVolume).replace(',', '.')) * 167);
            this.model.cwg = Number(Number(this.decimalPipe.transform(calculatedCwg > this.model.totalWeight ? calculatedCwg : this.model.totalWeight)).toFixed())
        }
        if (this.model.td == 'D') {

            //calculate total pieces
            this.model.calculatedPieces = 0;
            const pieces = this.model.packs.filter(f => f.pieces).map(f => f.pieces);
            if (pieces.length > 0) this.model.calculatedPieces = pieces.reduce((a, b) => a + b);

            // calculate total weight
            this.model.totalWeight = 0;
            this.model.packs.forEach(p => {
                this.model.totalWeight += (p.pieces * (p.perKg | 0));
            })


            // calculate line volume & calculate line total weight
            this.model.packs.forEach(i => {
                i.lineVolume = (((i.height | 0) * (i.lenght | 0) * (i.width | 0)) / 1000000) * (i.pieces | 0);
                i.totalWeight = (i.perKg | 0) * (i.pieces | 0);
                i.totalLoadMeter = (i.perLoadmeter | 0) * (i.pieces | 0);
            });


            // calculate total volume
            this.model.totalVolume = 0;
            const volumes = this.model.packs.filter(f => f.lineVolume).map(f => f.lineVolume);
            if (volumes.length > 0) this.model.totalVolume = volumes.reduce((a, b) => a + b);

            this.model.totalLoadingMeter = 0;
            const loadMeters = this.model.packs.filter(f => f.totalLoadMeter).map(f => f.totalLoadMeter);
            if (loadMeters.length > 0) this.model.totalLoadingMeter = loadMeters.reduce((a, b) => a + b);


            // calculate cwg
            this.model.cwg = 0;
            const cwg = ((Number(String(this.model.totalVolume).replace(',', '.')) || 0) * 167) > this.model.totalWeight ? ((this.model.totalVolume || 0) * 167) : this.model.totalWeight;
            this.model.cwg = Number(Number(this.decimalPipe.transform(cwg)).toFixed());
            this.calculateChargeableWeight();
        }
    }

    showUseTrms() {
        this.termsDialog = this.dialogService.open(DocViewerDialogComponent, {
            header: "view",
            showHeader: false,
            modal: true,
            closable: true,
            width: '50%',
            height: '100%',
            data: {filePath: `/assets/data/documents/terms_${this.translation.currentLang}.html`}
        });

    }


    calculateChargeableWeight() {
        const kg: number = this.model.totalWeight != null ? this.model.totalWeight : 0;
        const m3: number = this.model.totalVolume != null ? this.model.totalVolume : 0;
        const ldm: number = this.model.totalLoadingMeter != null ? this.model.totalLoadingMeter : 0;
        const ldmkg: number = 1750;
        const m3kg: number = 333;

        //En yuksek hacim hesaplaması
        let v1, v2, v3: number = 0;
        if (m3kg > 0) {
            v1 = kg / m3kg; // brut agirliktan hacim
        }
        v2 = ldm * 7; // lademetre uzerinden hacim
        v3 = m3;

        if (v1 >= v2 && v1 >= v3) {
            this.model.chargeableVolume = v1;
            this.model.chargeableVolume = Math.round(this.model.chargeableVolume * 1000) / 1000;
        } else if (v2 >= v1 && v2 >= v3) {
            this.model.chargeableVolume = v2;
            this.model.chargeableVolume = Math.round(this.model.chargeableVolume * 1000) / 1000;
        } else if (v3 >= v1 && v3 >= v2) {
            this.model.chargeableVolume = v3;
            this.model.chargeableVolume = Math.round(this.model.chargeableVolume * 1000) / 1000;
        }


        // En yuksek agirlik hesaplaması
        let w1, w2, w3: number = 0;
        w1 = kg; // Agirlik
        w2 = m3 * m3kg; // M3 uzerinden agirlik
        w3 = ldm * ldmkg; // Ldm uzerinden agirlik

        if (w1 >= w2 && w1 >= w3) {
            this.model.chargeableWeight = w1;
            this.model.chargeableWeight = Math.round(this.model.chargeableWeight * 1000) / 1000;
        } else if (w2 >= w1 && w2 >= w3) {
            this.model.chargeableWeight = w2;
            this.model.chargeableWeight = Math.round(this.model.chargeableWeight * 1000) / 1000;
        } else if (w3 >= w1 && w3 >= w2) {
            this.model.chargeableWeight = w3;
            this.model.chargeableWeight = Math.round(this.model.chargeableWeight * 1000) / 1000;
        }

        let l1, l2, l3: number = 0;
        l1 = kg / ldmkg; // Agirlik
        l2 = m3 / 7; // M3 uzerinden agirlik
        l3 = ldm; // Ldm uzerinden agirlik

        if (l1 >= l2 && l1 >= l3) {
            this.model.chargeableLdm = l1;
            this.model.chargeableLdm = Math.round(this.model.chargeableLdm * 1000) / 1000;
        } else if (l2 >= l1 && l2 >= l3) {
            this.model.chargeableLdm = l2;
            this.model.chargeableLdm = Math.round(this.model.chargeableLdm * 1000) / 1000;
        } else if (l3 >= l1 && l3 >= l2) {
            this.model.chargeableLdm = l3;
            this.model.chargeableLdm = Math.round(this.model.chargeableLdm * 1000) / 1000;
        }
    }

    onChangeKp(kp: any) {
        if (kp === 'F') {
            this.model.kp = kp;
            this.model.operationType = 'FTL';
        } else {
            this.model.kp = kp;
            this.model.operationType = 'LTL';
        }
        this.model.packs = [];
        this.model.packingType = null;
        this.model.pieces = 0;
        this.model.totalVolume = 0;
        this.model.totalWeight = 0;
        this.model.cwg = 0;
    }
}
