import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/user/authentication.service';
import { BaseFormComponentComponent } from '../shared/base-form-component/base-form-component.component';
import { UserModel } from '../models/auth/user.model';
import { countries } from '../../assets/data/country'
import { UserDocumentDTO } from '../models/auth/user-document.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DocViewerDialogComponent, acceptSubType } from '../components/doc-viewer-dialog/doc-viewer-dialog.component';
import { DocViewerBtnComponent } from '../components/doc-viewer-btn/doc-viewer-btn.component';
import { UserService } from '../services/user/user.service';



@Component({
    selector: 'app-register',
    templateUrl: './app.register.component.html',
    styleUrls: ['./app.register.component.scss']
})

export class AppRegisterComponent extends BaseFormComponentComponent implements OnInit {

    @ViewChild("uploader") uploader: ElementRef;

    @ViewChild("checkUserTerm")
    checkUserTerm: DocViewerBtnComponent;
    tcNo: boolean = false;
    userModel: UserModel = new UserModel();
    hide: boolean = true;
    hideReply: boolean = true;
    countries: any[] = [];
    selectedDocumentType: string = null;

    termsDialog: DynamicDialogRef;

    fileUrl: string;
    safeUrl: SafeUrl
    showDialog: boolean = false;
    showDocumentType: string = null;

    constructor(protected injector: Injector, private sanitizer: DomSanitizer, private userService: UserService, private authService: AuthenticationService, private router: Router) {
        super(injector);
    }

    ngOnInit(): void {
        this.countries = countries;
    }

    async register() {

        if (!this.userModel.isKvkk) {
            this.showMessage('warn', 'wrongKVKK');
            return;

        }
        if (!this.userModel.isMembershipAgreement) {
            this.showMessage('warn', 'wrongMembershipAgreement');
            return;
        }

        if (!this.tcNo && this.userModel?.dialCode?.code == 'TR' && !this.taxValidation(this.userModel.taxNo) ) {
            this.showMessage('warn', 'taxNoNotValid');
            return;
        }

        if (this.tcNo && this.userModel?.dialCode?.code == 'TR' && !this.tcValidation(this.userModel.identifyNo) ) {
            this.showMessage('warn', 'tcNoNotValid');
            return;
        }

        if (!(await this.isValid())) return;

        this.isLoading = true;

        try {

            this.userModel = await this.authService.register(this.userModel, this.translation.currentLang).toPromise();
            this.router.navigate(['/']);

            setTimeout(() => {
                this.showMessage('success', "registerSuccessMessage", null, 5000);
            }, (1000));

            this.isLoading = false;
        } catch {
            this.isLoading = false;
        }
    }

    goToDashboard() {
        this.router.navigate(['dashboard']);
    }

    validateAndWarningIdentityNo() {
        if (this.userModel.dialCode && this.userModel.dialCode.dial_code == '+90') {

            if (this.userModel.identifyNo && (String(this.userModel.identifyNo).length != 11 || typeof Number(this.userModel.identifyNo) != 'number')) {
                this.showMessage('warn', 'wrongIdentityNo');
            }



            if (this.userModel.taxNo && (this.userModel.taxNo.length != 10 || typeof Number(this.userModel.identifyNo) != 'number')) {
                this.showMessage('warn', 'wrongTaxNo');
            }
        }
    }

    showUploader(documentType, force = false) {
        this.selectedDocumentType = documentType;

        if (force || !this.userModel[this.selectedDocumentType]) {
            this.uploader.nativeElement.click();
            return;
        }

        if (this.userModel[this.selectedDocumentType]) {
            this.onViewFile(this.userModel[this.selectedDocumentType]);
            return;
        }


    }

    onViewFile(documentModel: UserDocumentDTO) {
        this.showDialog = true;
        if (['text/plain', 'application/pdf'].includes(documentModel.type)) {
            this.showDocumentType = 'pdf/txt'
        } else if (documentModel.type.includes('image')) {
            this.showDocumentType = 'image';
        } else if (documentModel.type.includes('officedocument') || documentModel.type.includes('application/msword') || documentModel.type.includes('application/octet-stream')) {

            this.showDocumentType = 'doc';
            /* eğer kayıtlı bir belge değil ise cache den okuyan url yi geri dönüyoruz. */
            if (!documentModel.uid) {
                this.fileUrl = this.authService.getDigitalUrlFromCache();
            }
            return;

        }

        const byteCharacters = this._base64ToArrayBuffer(documentModel.fileBase64Content);
        var blob = new Blob([byteCharacters], { type: documentModel.type });

        this.fileUrl = URL.createObjectURL(blob);
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileUrl);
    }

    _base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    async onSelectFile(event: any) {
        let warnMessage = null;
        const extentions = ["gif", "jpg", "tif", "jpeg", "png", "doc", "docx", "pdf"];

        if (this.showDialog) this.showDialog = false;
        let file = event.target.files[0] as File;
        event.target.value = null;

        const fileExtention = file.name.split(".").pop();

        const availableMineType = ['application/msword', 'image/png', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'image/jpeg'].includes(file.type)

        if (!availableMineType) warnMessage = 'wrongMineType';
        if (file.size > 5242880) warnMessage = 'maxSize5MB';

        if (warnMessage) {
            this.userModel[this.selectedDocumentType] = null;
            this.showMessage('warn', warnMessage);
            return;
        }

        const doc = new UserDocumentDTO();
        doc.type = file.type;
        doc.fileName = this.selectedDocumentType;
        doc.fileBase64Content = this.clearbase64STR(await this.toBase64(file))
        this.userModel[this.selectedDocumentType] = doc;

        /* doc veya docx uzantılı dosyaları görüntüleyebilmek için marketplace deki cache yazıyoruz 3. part eklenti google api sini kullanarak görüntülemeye olanak sağlıyor. bunun içinde bir link ile dosyaya erişmeye ihtiyaç duyuyor. cache deki dosyayı bir endpoint ile erişilebilir yapıyoruz. */
        try {
            if (['doc', 'docx'].includes(fileExtention)) {
                await this.authService.setCacheDocument(doc).toPromise();
            }
        } catch (error) {
            this.showMessage('error', 'errorOnCacheFile');
        }


    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    clearbase64STR(dataURI) {
        const BASE64_MARKER = ';base64,';
        const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        return dataURI.substring(base64Index);
    }


    showUseTrms(name) {
        this.termsDialog = this.dialogService.open(DocViewerDialogComponent, { header: "view", showHeader: false, modal: true, closable: true, width: '50%', height: '100%', data: { filePath: `/assets/data/documents/register_${this.translation.currentLang}.html`, name: name } });

    }

    showKvkk(name) {
        this.termsDialog = this.dialogService.open(DocViewerDialogComponent, { header: "view", showHeader: false, modal: true, closable: true, width: '50%', height: '100%', data: { filePath: `/assets/data/documents/kvkk_${this.translation.currentLang}.html`, name: name } });

    }

    accept(acceptType: acceptSubType, name: string) {
        if (acceptType.name != name) return;
        this.userModel[name] = acceptType.result;
    }

    taxValidation(vkn) {
            let tmp = 0;
            let sum = 0;

            if (vkn && vkn !== null && vkn.length === 10 && this.isInt(vkn)) {
                const lastDigit = parseInt(vkn.charAt(9));
                for (let i = 0; i < 9; i++) {
                    const digit = parseInt(vkn.charAt(i));
                    tmp = (digit + 10 - (i + 1)) % 10;
                    sum = tmp === 9 ? sum + tmp : sum + ((tmp * (Math.pow(2, 10 - (i + 1))) % 9));
                }
                return lastDigit === (10 - (sum % 10)) % 10;
            }
            return false;
    }

     tcValidation(TCNO) {
         TCNO = String(TCNO);

         var tek = 0,
            cift = 0,
            TCToplam = 0,
            sonuc = 0;


        if (TCNO?.length != 11) return false;
        if (isNaN(TCNO)) return false;
        if (TCNO[0] == 0) return false;

        tek = parseInt(TCNO[0]) + parseInt(TCNO[2]) + parseInt(TCNO[4]) + parseInt(TCNO[6]) + parseInt(TCNO[8]);
        cift = parseInt(TCNO[1]) + parseInt(TCNO[3]) + parseInt(TCNO[5]) + parseInt(TCNO[7]);

         tek = tek * 7;
         sonuc = tek - cift;
         if (sonuc % 10 != TCNO[9]) return false;

         for (var i = 0; i < 10; i++) {
             TCToplam += parseInt(TCNO[i]);
         }

         if (TCToplam % 10 != TCNO[10]) return false;

         return true;
    }
    isInt(s) {
        for (let a = 0; a < s.length; a++) {
            if (a === 0 && s.charAt(a) === '-') continue;
            if (!isFinite(s.charAt(a))) return false;
        }
        return true;
    }

    protected readonly window = window;
}
