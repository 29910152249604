import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import {MenuItem} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {ChangeLanguageService} from "../../services/change-language.service";
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AdminDialogComponent } from 'src/app/components/admin-dialog/admin-dialog.component';
import { LoginDto } from 'src/app/models/auth/login-dto.model';
import { AppMainComponent } from 'src/app/app.main.component';
import { FixedPriceService } from 'src/app/services/fixedPrice/fixed-price.service';
import Globe from "globe.gl";
import * as THREE from 'three';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {


    isDropdownOpen: boolean = false;
    adminDialogRef: DynamicDialogRef;
    fixedPrices: any[];
    imageList = ['air1.jpg', 'air2.jpg', 'air3.jpg', 'air4.jpg', 'air5.jpg', 'air6.jpg', 'sea1.jpg', 'sea2.jpg', 'sea3.jpg', 'sea4.jpg',  'sea5.jpg', 'sea6.jpg', 'truck1.jpg', 'truck2.jpg', 'truck3.jpg']


    constructor(public translation: TranslateService, public appMain: AppMainComponent, private languageService: ChangeLanguageService, private dialogService: DialogService, private fixedPriceService: FixedPriceService) {
        if(document.querySelector("div[data-id=zsalesiq]")) document.querySelector("div[data-id=zsalesiq]").remove();
        Object.keys(localStorage).filter(k => k != 'rememberMe' && k != 'lang' && k != 'uid' && k != 'efl-saved-consent' && k != 'efl-uuid').forEach(key => localStorage.removeItem(key))




    }

    initGlobe(){
        let height = 1000;
        if (screen.width < 1000) height = 700;
        if (screen.width < 700) height = 600;
        if (screen.width < 500) height = 500;
        if (screen.width < 400) height = 400;
        const world = Globe({ animateIn: true }).backgroundColor('#00000000').height(height)
        (document.getElementById('globeViz'))
            .globeImageUrl('//unpkg.com/three-globe@2.30.0/example/img/earth-day.jpg')
            .bumpImageUrl('//unpkg.com/three-globe/example/img/earth-topology.png');

        // Auto-rotate
        world.controls().autoRotate = true;
        world.controls().autoRotateSpeed = 0.35;
        world.controls().enableZoom = false;

        // Add clouds sphere
        const CLOUDS_IMG_URL = '/assets/img/clouds.png'; // from https://github.com/turban/webgl-earth
        const CLOUDS_ALT = 0.004;
        const CLOUDS_ROTATION_SPEED = -0.006; // deg/frame

        new THREE.TextureLoader().load(CLOUDS_IMG_URL, cloudsTexture => {
            const clouds = new THREE.Mesh(
                new THREE.SphereGeometry(world.getGlobeRadius() * (1 + CLOUDS_ALT), 75, 75),
                new THREE.MeshPhongMaterial({ map: cloudsTexture, transparent: true })
            );
            world.scene().add(clouds);

            (function rotateClouds() {
                clouds.rotation.y += CLOUDS_ROTATION_SPEED * Math.PI / 180;
                requestAnimationFrame(rotateClouds);
            })();
        });
    }

    async ngOnInit() {
        const ipData = await this.fixedPriceService.getIpLocation(location.href.includes('https'));
        if(ipData.countryCode != 'TR') {
           this.changeTranslate('en');
        }
        this.initGlobe();
    }


    options: AnimationOptions = {
        path: '/assets/lottie/particul.json',
    };

    productItemLength = 0;

    styles: Partial<CSSStyleDeclaration> = {
        width: '100%',
        margin: '0 auto',
    };
    items: any[] = [1, 2];

    faq = [
        {
            title: 'registerTab',
            command: () => this.questionList = this.faq[0].details,
            details: [
                {
                    question: 'registerQ1',
                    answer: 'registerA1'
                },
                {
                    question: 'registerQ2',
                    answer: 'registerA2'
                },
                {
                    question: 'registerQ3',
                    answer: 'registerA3'
                },
                {
                    question: 'registerQ4',
                    answer: 'registerA4'
                },
            ]
        },
        {
            title: 'priceTab',
            command: () => this.questionList = this.faq[1].details,
            details: [
                {
                    question: 'priceQ1',
                    answer: 'priceA1'
                },
                {
                    question: 'priceQ2',
                    answer: 'priceA2'
                },
                {
                    question: 'priceQ3',
                    answer: 'priceA3'
                },
                {
                    question: 'priceQ4',
                    answer: 'priceA4'
                },
                {
                    question: 'priceQ5',
                    answer: 'priceA5'
                },
                {
                    question: 'priceQ6',
                    answer: 'priceA6'
                },
                {
                    question: 'priceQ7',
                    answer: 'priceA7'
                },
                {
                    question: 'priceQ8',
                    answer: 'priceA8'
                },
            ]
        },
        {
            title: 'bookingTab',
            command: () => this.questionList = this.faq[2].details,
            details: [
                {
                    question: 'bookingQ1',
                    answer: 'bookingA1'
                },
                {
                    question: 'bookingQ2',
                    answer: 'bookingA2'
                },
                {
                    question: 'bookingQ3',
                    answer: 'bookingA3'
                },
                {
                    question: 'bookingQ4',
                    answer: 'bookingA4'
                },
                {
                    question: 'bookingQ5',
                    answer: 'bookingA5'
                },
                {
                    question: 'bookingQ6',
                    answer: 'bookingA6'
                },
                {
                    question: 'bookingQ7',
                    answer: 'bookingA7'
                },
            ]
        },
    ];
    activeItem: MenuItem = this.faq[0];
    questionList = this.faq[0].details;


    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    changeLanguage(lang: string) {
        this.changeTranslate(lang)
        this.toggleDropdown();
    }

    changeTranslate(lang) {
        this.translation.use(lang);
        localStorage.setItem('lang', lang);
        localStorage.setItem('locale', lang == 'tr' ? 'tr-TR': 'en-US');
        this.languageService.languageChanged.next(lang);
    }

    protected readonly screen = screen;

    productItemLengthCheck(val) {
        this.productItemLength = val;
    }


    async showAdminAuthDialog(loginDTO: LoginDto) {
        const header = await this.translation.get('continueAsAdmin').toPromise();
        this.adminDialogRef = this.dialogService.open(AdminDialogComponent, { header: header, width: '600px', height: 'auto', closeOnEscape: false, baseZIndex: 999999, closable: false,  data: {loginDTO: loginDTO}});
    }

    getPrices(values: any[]) {
        this.fixedPrices = JSON.parse(JSON.stringify(values));
        this.fixedPrices.forEach(i => i.image = this.rastgeleElemanSec(i.operationType))

        setTimeout(() =>{
            const el = document.getElementById('fixedPricesLayout');
            el.scrollIntoView({behavior: 'smooth'});
        }, 100);
    }


    getRandomImageList(operationType){
        let list = [];
        if (operationType === 'TRUCK'){
            list = this.imageList.filter(i => i.includes('truck'));
        }else if (operationType === 'SEA'){
            list = this.imageList.filter(i => i.includes('sea'));
        }else if (operationType === 'AIR'){
            list = this.imageList.filter(i => i.includes('air'));
        }
        return list;
    }
    rastgeleElemanSec<T>(operationType): T | undefined {
        const list = this.getRandomImageList(operationType);
        if (list.length === 0) {
            return undefined; // Liste boşsa undefined döndür
        }
        const rastgeleIndex = Math.floor(Math.random() * list.length);
        return list[rastgeleIndex];
    }


    protected readonly window = window;
}
