import { Component, Input, Output, ViewEncapsulation } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { TruckModel } from 'src/app/models/truck/truck-model';
import { MarketTruckProductsModel } from 'src/app/models/truck/market-truck-products.model';

export interface ReservationData {
  selectedProduct: MarketTruckProductsModel | null;
  marketProductsDefId: number | null;
  productId: number | null;
}


@Component({
  selector: 'app-truck-list',
  templateUrl: './truck-list.component.html',
  styleUrls: ['./truck-list.component.scss']
})


export class TruckListComponent {

  @Input() model: TruckModel = null;
  @Input() loading: boolean = false;
  @Output() makeReservation: EventEmitter<ReservationData> = new EventEmitter<ReservationData>();
  @Output() priceInfo: EventEmitter<MarketTruckProductsModel> = new EventEmitter<MarketTruckProductsModel>();



  _makeReservation(selectedProduct: MarketTruckProductsModel = null, marketProductsDefId = null, productId = null) {
    this.makeReservation.emit({selectedProduct,marketProductsDefId,productId})
  }
  _priceInfo(product: MarketTruckProductsModel) {
    this.priceInfo.emit(product)
  }
}
