import { Component, Input, Output } from '@angular/core';
import { SeaModel } from 'src/app/models/sea/sea-model';
import { MarketSeaProductsModel } from 'src/app/models/sea/market-sea-products.model';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ChangeLanguageService } from 'src/app/services/change-language.service';
import { SeaPriceRequestComponent } from 'src/app/pages/sea/price-request/price-request.component';

export interface ReservationData {
  selectedProduct: MarketSeaProductsModel | null;
  marketProductsDefId: number | null;
  productId: number | null;
}


@Component({
  selector: 'app-sea-card-modal',
  templateUrl: './sea-card-modal.component.html',
  styleUrls: ['./sea-card-modal.component.scss'],
})


export class SeaCardModalComponent {

  
  product: MarketSeaProductsModel  = null;
  model: SeaModel = null;
  lang: string = "tr";
  surchargeDialogRef: DynamicDialogRef;
  requestComponent: SeaPriceRequestComponent = null;

  allProducts: MarketSeaProductsModel[] = [];
  selectedProductIndex: number = null;


  constructor(public translation: TranslateService, public config: DynamicDialogConfig, private languageService: ChangeLanguageService,  public ref: DynamicDialogRef) {

    this.model = this.config.data.model;
    this.model.products = this.config.data.products; 
    this.product = this.model.products[0];
    this.allProducts = this.config.data.allProducts;

    this.lang =  this.languageService.getCurrentLanguage();
    this.requestComponent = this.config.data.requestComponent;

  }


  closeDialog() {

    // vade vb işlem yapıldı ise ürünü bul ve ana modeli güncelle
    let product = this.allProducts.find(s => s.uuid == this.model.products[0].uuid);
    product = this.model.products[0];


    this.allProducts.forEach(p => {
      if(p.uuid == this.model.products[0].uuid) {
        p.freights =  this.model.products[0].freights;
        p.selectedDue = this.model.products[0].selectedDue;
      }
      
    })

    this.model.products = this.allProducts;
    this.ref.close(this.allProducts);
  }

  _makeReservation(selectedProduct: MarketSeaProductsModel = null, marketProductsDefId = null, productId = null) {
    this.requestComponent.makeReservation(selectedProduct,marketProductsDefId,productId)
    this.closeDialog();
  }
  _priceInfo(product: MarketSeaProductsModel) {
    this.requestComponent.priceInfo(product);
  }
}
