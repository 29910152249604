import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AirCalculatedFreightModel } from 'src/app/models/air/air-calculated-freight.model';
import { AirModel } from 'src/app/models/air/air-model';
import { MarketAirProductsModel } from 'src/app/models/air/market-air-products.model';
import { MarketPlaceDueDatesHeaderDetail } from 'src/app/models/market-place-due-dates-header-detail.model';
import { MarketSeaProductsModel } from 'src/app/models/sea/market-sea-products.model';
import { SeaCalculatedFreightModel } from 'src/app/models/sea/sea-calculated-freight.model';
import { SeaModel } from 'src/app/models/sea/sea-model';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AirSearchService } from 'src/app/services/air/air-search.service';
import { SeaSearchService } from 'src/app/services/sea/sea-search.service';
import { Subject } from 'rxjs';

export const changeDueSub = new Subject<any>();

@Component({
  selector: 'app-due-component',
  templateUrl: './due-component.component.html',
  styleUrls: ['./due-component.component.scss']
})


export class DueComponentComponent implements OnInit, OnDestroy {



  loading: boolean = false;

  @Input("ref") parentDialog: DynamicDialogRef = null;

  @Input("model") airModel: AirModel = null;
  originalFreightAir: AirCalculatedFreightModel = new AirCalculatedFreightModel();

  @Input("model") seaModel: SeaModel = null;
  originalFreightSea: SeaCalculatedFreightModel = new SeaCalculatedFreightModel();

  @Input("product") productAir: MarketAirProductsModel = null;
  @Input("product") productSea: MarketSeaProductsModel = null;

  @Input("operation") operation: string = null;

  @Output() onChangeDue: EventEmitter<any> = new EventEmitter();
  @Output() onApproveDue: EventEmitter<any> = new EventEmitter();


  model: any;
  product: any;
  onFirstInit: boolean = false;


  selectedDueFromModel: MarketPlaceDueDatesHeaderDetail = null;

  options: MarketPlaceDueDatesHeaderDetail[] = [];

  private _selectedDueDate: MarketPlaceDueDatesHeaderDetail = null;

  private _oldSelectedDueDate: MarketPlaceDueDatesHeaderDetail = null;

  get selectedDueDate(): MarketPlaceDueDatesHeaderDetail {
    return this._selectedDueDate;
  }

  set selectedDueDate(value: MarketPlaceDueDatesHeaderDetail) {

    // if ((this._oldSelectedDueDate && value && this._oldSelectedDueDate.id != value.id)) this.reCalculcateDuePercent(this._oldSelectedDueDate.percent, false);

    // if (!value && this._oldSelectedDueDate) this.reCalculcateDuePercent(this._oldSelectedDueDate.percent, false);

    // if (value && this._oldSelectedDueDate?.id != value.id) {
    //   this.reCalculcateDuePercent(value.percent, true);
    // }


    this._selectedDueDate = value;
    this._oldSelectedDueDate = value;


  }

  constructor(private translation: TranslateService, private airService: AirSearchService, private seaService: SeaSearchService) { }

  async ngOnInit() {

    this.model = this.getModel();
    this.product = this.getProduct();


    // this.onFirstInit = true;
    this._oldSelectedDueDate = this.model.selectedDueDate;


    // set selected due
    if(this.product.dueDates && this.product?.selectedDue) {
      this.selectedDueDate = this.product.dueDates.find(s => s.id == this.product.selectedDue?.id);
    }

    this.setOptionsNameWithTranslation();

  }

  ngOnDestroy(): void {

    // vade değiştirildiğinde.
    // if(!this.model.selectedDueDate && this.selectedDueDate){
    //   this.reCalculcateDuePercent(this.selectedDueDate.percent, false);
    // }

    // vade seçimi yapılmış ancak uygulanmadan çıkımış ise
    // if(this.selectedDueDate && this.selectedDueFromModel && this.selectedDueDate.id != this.selectedDueFromModel.id && this._oldSelectedDueDate) {
    //   this.reCalculcateDuePercent(this._oldSelectedDueDate.percent, false);
    //   this.reCalculcateDuePercent(this.selectedDueFromModel.percent, true);
    // }

    // seçim yapılmadan ancak ana modelde vade kayıtlı ise ve  pencere kapatıldığında.
    // if(!this.model.selectedDueDate && this.selectedDueFromModel) {
    //   this.model.selectedDueDate = this.selectedDueFromModel;
    //   this.reCalculcateDuePercent(this.selectedDueFromModel.percent, true);
    // }

    if(this.onChangeDue) this.onChangeDue.unsubscribe();
    if(this.onApproveDue) this.onApproveDue.unsubscribe();

  }
  async setOptionsNameWithTranslation() {
    const daysTranslation = await this.translation.get('day').toPromise();
    const rateTranslation = await this.translation.get('rate').toPromise();
    this.product.dueDates.forEach(f => f.name = `${f.value} ${daysTranslation}`);

  }

  getModel() {
    return this.airModel || this.seaModel;
  }

  getProduct() {
    return this.productAir || this.productSea;
  }

  onSelectDueDate(selected: MarketPlaceDueDatesHeaderDetail) {
    if (!selected) this.model.selectedDueDate = null;
  }

  reCalculcateDuePercent(percent, add: boolean = false) {

    if (this.product?.freights?.originCharges) {
      this.product.freights.originCharges.forEach(i => i.grandTotal = this.calculate(i.grandTotal, percent, add))
    }

    if (this.product?.freights?.destinationCharges) {
      this.product.freights.destinationCharges.forEach(i => i.grandTotal = this.calculate(i.grandTotal, percent, add))
    }

    if (this.product?.freights?.grandTotal) {
      this.product.freights.grandTotal = this.calculate(this.product.freights.grandTotal, percent, add);
    }

    if (this.product?.frontTariff) {
      this.product.frontTariff.grandTotal = this.calculate(this.product.frontTariff.grandTotal, percent, add);
    }

    if (this.product?.endTariff) {
      this.product.endTariff.grandTotal = this.calculate(this.product.endTariff.grandTotal, percent, add);
    }

    this.onChangeDue.emit(JSON.parse(JSON.stringify(this.product)));

  }

  calculate(value: number, percent: number, add: boolean) {
    return add ? (value * percent).fixNumberPercent().valueOf() : (value / percent).fixNumberPercent().valueOf()
  }

  async approveDueDate() {
    try {

      this.model.selectedDueDate = this.selectedDueDate;
      this.loading = true;

      if(this.operation == 'AIR') {
        this.product = await this.airService.due(this.product, this.selectedDueDate?.id, this.model.quoteNo, this.model.id).toPromise();
      } else if (this.operation == 'SEA') {
        this.product = await this.seaService.due(this.product, this.selectedDueDate?.id, this.model.quoteNo, this.model.id).toPromise();
      }


      if(this.model.selectedProduct && this.model.selectedProduct.id == this.product.id) {
          this.product.hasInsurance = this.model.selectedProduct

          if (this.model.hasInsurance){
              this.product.freights.grandTotal =  this.product.freights.grandTotal + this.model.insurance;
          }

        this.model.selectedProduct = this.product;
      }

      if(this.model.products) {
        let idx = this.model.products.findIndex(f => f.id == this.product.id);
        if(idx > -1) this.model.products[idx] = this.product
      }
      if (this.model.selectedDueDate){
          this.model.disablePaymentType = true;
          this.model.agencyFeeAdded = false;
          this.model.agencyFee = 0;
      }else {
          this.model.disablePaymentType = false;
      }
      this.calculateAgencyFee();

      this.onApproveDue.emit(this.product);
      changeDueSub.next(this.product);

      this.loading = false;
    } catch(e) {
      console.log(e);
      this.loading = false;
    }

  }


    calculateAgencyFee() {

        if (!(this.model?.paymentCompanyType == 'CUSTOMER' && this.model?.isUserAgency && !this.model?.agencyFeeAdded) && !this.model?.selectedDueDate) {
           
            if (this.model?.paymentCompanyCountry != null && !this.model.paymentCompanyCountry?.name?.includes('TÜRKİYE') && !this.model.paymentCompanyCountry?.name?.includes('TURKEY') && !this.model.agencyFeeAdded) {
                
                this.model.agencyFee = Number((this.model.selectedProduct.freights.grandTotal * 0.05).toFixed(2));
                if (this.model?.selectedProduct?.freights?.currency == 'USD') {
                    if (this.model.agencyFee < 25) this.model.agencyFee = 25;
                } else {
                    if (this.model.agencyFee < 20) this.model.agencyFee = 20;
                }
                this.model.selectedProduct.freights.grandTotal = this.model.selectedProduct.freights.grandTotal + this.model.agencyFee;
                this.model.agencyFeeAdded = true;
            } else if (this.model?.paymentCompanyCountry != null && !this.model.paymentCompanyCountry?.name?.includes('TÜRKİYE') && !this.model.paymentCompanyCountry?.name?.includes('TURKEY') && this.model.agencyFeeAdded) {
        
                this.model.selectedProduct.freights.grandTotal = this.model.selectedProduct.freights.grandTotal - this.model.agencyFee;
                this.model.agencyFee = Number((this.model.selectedProduct.freights.grandTotal * 0.05).toFixed(2));
                if (this.model?.selectedProduct?.freights?.currency == 'USD') {
                    if (this.model.agencyFee < 25) this.model.agencyFee = 25;
                } else {
                    if (this.model.agencyFee < 20) this.model.agencyFee = 20;
                }
                this.model.selectedProduct.freights.grandTotal = this.model.selectedProduct.freights.grandTotal + this.model.agencyFee;
                this.model.agencyFeeAdded = true;
            } else if (this.model.agencyFeeAdded && (this.model.paymentCompanyCountry?.name?.includes('TURKEY') || this.model.paymentCompanyCountry?.name?.includes('TÜRKİYE'))) {
           
                this.model.selectedProduct.freights.grandTotal = this.model.selectedProduct.freights.grandTotal - this.model.agencyFee;
                this.model.agencyFeeAdded = false;
                this.model.agencyFee = null;
            }
        } else if (this.model?.paymentCompanyType == 'CUSTOMER' && this.model?.isUserAgency && this.model?.agencyFeeAdded){
            
            this.model.selectedProduct.freights.grandTotal = this.model.selectedProduct.freights.grandTotal - this.model.agencyFee;
            this.model.agencyFeeAdded = false;
            this.model.agencyFee = null;
        }
    }

}
