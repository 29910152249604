import { AlternativeSegment } from "./alternative-segment";
import { CompanySearchResultModel } from "./company/company-search-result.model";
import { CountryMinimizedModel } from "./country-minimized.model";
import { MarketPlaceDueDatesHeaderDetail } from "./market-place-due-dates-header-detail.model";
import { MinDTO } from "./min-dto";
import { ZipModel } from "./zip-model";

export class AbstractModel {
    id: number;
    incoterm: string = null;
    service: string;
    offerType: string;
    paymentCompanyCountry: MinDTO;
    paymentCompanyCity: MinDTO;
    paymentCompanyAddress: string;
    isUserAgency:boolean = false;
    disablePaymentType: boolean = false;

    agencyFee: number;
    agencyFeeAdded: boolean;
    readinessDate: Date;
    goodPrice: number;
    gtip: string;
    goodDefinition: string;

    insurance: number = 0;
    hasInsurance: boolean = false;
    hasAllocation: boolean = false;

    commodity: string;
    packingType: string;


    // DEPARTURE PORT / ARRIVAL PORT
    departureCountry: CountryMinimizedModel;
    departurePort: MinDTO;
    departureZip: ZipModel;
    departureZipTxt: string;
    departureAddress: string;

    arrivalCountry: CountryMinimizedModel;
    arrivalPort: MinDTO;
    arrivalZip: ZipModel;
    arrivalZipTxt: string;
    arrivalAddress: string;



    selectedDueDate: MarketPlaceDueDatesHeaderDetail;

    quoteNo: string;
    shipmentNo: string;
    offerStatus: string
    offerSubStatus: string;


    segment: MinDTO;
    alternativeSegments: AlternativeSegment[];

    // SHIPMENT DETAIL
    shipper: CompanySearchResultModel;
    shipperText: string;
    shipperCountry: MinDTO;
    shipperCity: MinDTO;
    shipperAddress: string;
    shipperEmail: string;
    shipperPhone: string;

    consignee: CompanySearchResultModel;
    paymentCompany: CompanySearchResultModel;
    paymentCompanyText: string;
    consigneeText: string;
    consigneeCountry: MinDTO;
    consigneeCity: MinDTO;
    consigneeAddress:string;
    consigneeEmail: string;
    consigneePhone: string;
    paymentCompanyEmail: string;
    paymentCompanyPhone: string;

    description: string;
    unApprovedPrice: boolean = false;
    marketProductsDefId: number;
    pricingDone: boolean = false;
    isNoPrice: boolean = false;
    paymentCompanyType: any;
    departureCity: MinDTO;
    arrivalCity: MinDTO;




}
