import { Component, ElementRef, Input, Output, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MarketAirProductsModel } from 'src/app/models/air/market-air-products.model';
import { AirModel } from 'src/app/models/air/air-model';

export interface ReservationData {
  selectedProduct: MarketAirProductsModel | null;
  marketProductsDefId: number | null;
  productId: number | null;
}

const weekDaysMap: { [key: number]: string } = {
  1: "Pts",
  2: "Sal",
  3: "Çar",
  4: "Per",
  5: "Cum",
  6: "Cmt",
  7: "Paz"
};


@Component({
  selector: 'app-air-card',
  templateUrl: './air-card.component.html',
  styleUrls: ['./air-card.component.scss'],
})


export class AirCardComponent {

  @ViewChildren('details') detailsElements: QueryList<ElementRef>;

  @Input() model: AirModel = null;
  @Input() loading: boolean = false;
  @Output() makeReservation: EventEmitter<ReservationData> = new EventEmitter<ReservationData>();
  @Output() priceInfo: EventEmitter<MarketAirProductsModel> = new EventEmitter<MarketAirProductsModel>();
  @Output() frontTariffNotFound: EventEmitter<any> = new EventEmitter<any>();
  @Output() endTariffNotFound: EventEmitter<any> = new EventEmitter<any>();
  @Output() frontEndTariffNotFound: EventEmitter<any> = new EventEmitter<any>();




  constructor(public translation: TranslateService) {

  }

  _makeReservation(selectedProduct: MarketAirProductsModel = null, marketProductsDefId = null, productId = null) {
    this.makeReservation.emit({selectedProduct,marketProductsDefId,productId})
  }
  _priceInfo(product: MarketAirProductsModel) {
    this.priceInfo.emit(product)
  }

  _frontTariffNotFound() {
    this.frontTariffNotFound.emit(null);
  }
  _endTariffNotFound() {
    this.endTariffNotFound.emit(null);
  }
  _frontEndTariffNotFound() {
    this.frontEndTariffNotFound.emit(null);
  }
  toggleDetails(index: number): void {
    const detailsElement = this.detailsElements.toArray()[index].nativeElement;
    const isExpanded = detailsElement.classList.contains('expanded');

    if (isExpanded) {
      detailsElement.style.maxHeight = '0';
      detailsElement.classList.remove('expanded');
    } else {
      detailsElement.style.maxHeight = detailsElement.scrollHeight + 'px';
      detailsElement.classList.add('expanded');
    }
  }

  getShortWeekdays(days: string[]): string {
    if (!days || days.length === 0) return "";

    const allDays = ["1", "2", "3", "4", "5", "6", "7"];
    if (days.length === 7 && allDays.every(day => days.includes(day))) {
        return "Her Gün";
    }

    return days.map(day => weekDaysMap[Number(day)] || "").filter(Boolean).join(",");
  }

}
