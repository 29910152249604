import { Component, AfterViewInit, Inject } from '@angular/core';
import { MenuService } from './app.menu.service';
import { PrimeNGConfig } from 'primeng/api';
import { AppComponent } from './app.component';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/user/authentication.service';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html',
})
export class AppMainComponent implements AfterViewInit {

    sidebarStatic: boolean;

    sidebarActive = false;

    staticMenuMobileActive: boolean;

    menuClick: boolean;

    topbarItemClick: boolean;

    activeTopbarItem: any;

    topbarMenuActive: boolean;

    staticMenuActive: boolean;

    menuMobileActive: boolean;

    searchClick = false;

    search = false;

    rightPanelClick: boolean;

    rightPanelActive: boolean;

    configActive: boolean;

    configClick: boolean;

    menuHoverActive = false;
    
    loadingProgress: boolean = false;

    constructor(@Inject(DOCUMENT) private doc: any, private menuService: MenuService, private primengConfig: PrimeNGConfig, private router: Router, private authService: AuthenticationService,
        public app: AppComponent) {
            this.appendScriptSrc('/assets/js/google.js');
    }

    onLayoutClick() {
        if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }

        if (!this.menuClick && (this.isHorizontal() || this.isSlim())) {
            this.menuService.reset();
        }

        if (this.configActive && !this.configClick) {
            this.configActive = false;
        }

        if (!this.rightPanelClick) {
            this.rightPanelActive = false;
        }

        if (!this.menuClick) {
            if (this.staticMenuMobileActive) {
                this.staticMenuMobileActive = false;
            }

            this.menuHoverActive = false;
            this.unblockBodyScroll();
        }

        if (!this.searchClick) {
            this.search = false;
        }

        this.searchClick = false;
        this.configClick = false;
        this.topbarItemClick = false;
        this.menuClick = false;
        this.rightPanelClick = false;
    }

    onToggleMenuClick(event: Event) {
        this.staticMenuActive = !this.staticMenuActive;
        event.preventDefault();
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.topbarMenuActive = false;
        this.rightPanelActive = false;

        if (this.isMobile()) {
            this.staticMenuMobileActive = !this.staticMenuMobileActive;
            if (this.staticMenuMobileActive) {
                this.blockBodyScroll();
            } else {
                this.unblockBodyScroll();
            }
        }

        event.preventDefault();
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        } else {
            this.activeTopbarItem = item;
        }

        if (item.className === 'topbar-item search-item') {
            this.search = !this.search;
            this.searchClick = !this.searchClick;
        }

        event.preventDefault();
    }

    async onRightPanelClick(event) {
        this.rightPanelClick = true;
        this.rightPanelActive = !this.rightPanelActive;

        await this.authService.logout().toPromise();
        
        this.staticMenuMobileActive = false;
        Object.keys(localStorage).filter(k => k != 'rememberMe' && k != 'lang' && k != 'uid' && k != 'efl-saved-consent' && k != 'efl-uuid').forEach(key => localStorage.removeItem(key))


        
        this.router.navigate(['/']);
        
        if(event) event.preventDefault();
    }

    onRippleChange(event) {
        this.app.ripple = event.checked;
        this.primengConfig.ripple = event.checked;
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    onSidebarClick($event) {
        this.menuClick = true;
    }

    onToggleMenu(event) {
        this.menuClick = true;
        this.sidebarStatic = !this.sidebarStatic;

        event.preventDefault();
    }

    onSidebarMouseOver(event) {
        if (this.app.menuMode === 'sidebar' && !this.sidebarStatic) {
            this.sidebarActive = !this.isMobile();
        }
    }

    onSidebarMouseLeave($event) {
        if (this.app.menuMode === 'sidebar' && !this.sidebarStatic) {
            setTimeout(() => {
                this.sidebarActive = false;
            }, 250);
        }
    }

    isSlim() {
        return this.app.menuMode === 'slim';
    }

    isHorizontal() {
        return this.app.menuMode === 'horizontal';
    }

    isDesktop() {
        return window.innerWidth > 991;
    }

    isMobile() {
        return window.innerWidth <= 991;
    }

    blockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    }

    unblockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    ngAfterViewInit(): void {
        if(window.innerWidth > 768) {
            this.appendScriptSrc('/assets/js/zoho.js');
        }
    }

    appendScriptSrc(path) {
        let node = document.createElement('script'); 
        node.src = path
        node.type = 'text/javascript'; 
        node.async = true; 
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node); 
        console.warn(`added ${path}`)
    }

}
