import { SeaCalculatedChargesModel } from "./sea-calculated-charges.model";
import {AirCalculatedChargesModel} from "../air/air-calculated-charges.model";

export class SeaCalculatedFreightModel {
    uuid: string;
    seaFreightTariffDetId: number;
    unitPrice: number;
    unitBuyingFreight: number;
    tonnage: number;
    setTariffTonnage: number;
    containerType: string;
    containerCount: number;
    totalFreight: number;
    grandTotal: number;
    currencyRate; //kur cevrimi içi: number;
    tariffCurrency: string;
    currency: string;
    errorCode: string;
    freeTime: number;
    isMinFreight: boolean;
    freightTariffValidFrom: Date;
    freightTariffValidTo: Date;
    freightTariffDetValidFrom: Date;
    freightTariffDetValidTo: Date;
    originCharges: SeaCalculatedChargesModel[] = [];
    insuranceCharge: SeaCalculatedChargesModel;
    agencyFeeCharge: SeaCalculatedChargesModel;
    destinationCharges: SeaCalculatedChargesModel[] = [];
    etd: Date;
    eta: Date;
    cutOffDate: Date;
    shipInfo: string;
    isLastPrice: boolean = false;
}
