import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'minusDays'
})
export class MinusDaysPipe implements PipeTransform{
    constructor() { }
    transform(value: string | Date | null | undefined, days: number = 1): Date | null {
      if (!value) return null; // Geçersizse null döndür
  
      let date: Date;
  
      if (typeof value === 'string') {
        if (value.includes('T')) {
          date = new Date(value + 'Z'); // ISO formatını doğru işle
        } else {
          return null;
        }
      } else {
        date = value;
      }
  
      if (isNaN(date.getTime())) {
        return null;
      }
  
      date.setDate(date.getDate() - days); // -1 gün çıkar
  
      return date; // Şimdi Date nesnesi döndürülüyor!
    }
}