import { Injectable } from '@angular/core';
import { LoginResponseModel } from '../models/auth/login-response.model';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SseService {
    private eventSource: EventSource | null = null;

    // Gelen mesajları paylaşmak için Subject
    private messageSubject: Subject<string> = new Subject<string>();
    private errorSubject: Subject<any> = new Subject<any>();

    public onChangeProduct: Subject<any> = new Subject<any>();

    /**
     * SSE bağlantısı açar
     * @param LoginResponseModel Login modeli
     * @param onMessageCallback Gelen mesajları işlemek için bir callback fonksiyonu
     * @param onErrorCallback Hata durumunda çağrılacak bir callback fonksiyonu
     */
    connect(login: LoginResponseModel): void {

        if (!login || !login?.uuid) {
            console.error('Kullanıcı ID eksik.');
            return;
        }

        // SSE bağlantısını başlat
        
        this.eventSource = new EventSource(`${environment.orchUrl}/stream/sseFlow/connect?requestId=${login.uuid}`);

        console.log(this.eventSource)
        // Gelen mesajları dinle ve Subject'e ilet
        this.eventSource.onmessage = (event) => {
            console.log('Mesaj alındı:', event.data);
            this.messageSubject.next(event.data); // Gelen mesajları yayınla
        };

        this.eventSource.onopen = () => {
            console.warn("EventSource bağlantısı başarıyla açıldı.");
        };

        // Hata durumlarını dinle ve Subject'e ilet
        this.eventSource.onerror = (error) => {
            console.error('SSE bağlantı hatası:', error);
            this.errorSubject.next(error); // Hataları yayınla
        };
    }

    /**
     * Gelen mesajları dinlemek için Observable döner
     */
    getMessages(): Observable<string> {
        return this.messageSubject.asObservable(); // Mesajlara abone olma imkanı
    }

    /**
     * Hataları dinlemek için Observable döner
     */
    getErrors(): Observable<any> {
        return this.errorSubject.asObservable(); // Hatalara abone olma imkanı
    }

    getOnChangeProduct(): Observable<any> {
        return this.onChangeProduct.asObservable();
    }

    /**
     * SSE bağlantısını kapatır
     */
    disconnect(): void {
        if (this.eventSource) {
            this.eventSource.close();
            console.log('SSE bağlantısı kapatıldı.');
            this.eventSource = null;
        } else {
            console.warn('SSE bağlantısı zaten kapalı.');
        }
    }
}
